$icon-height: 30px;
$btn-padding-width: 4px;

$btn-height: $icon-height + ($btn-padding-width * 2) + ($border-width * 2);
$btn-height-sm: ($icon-height / 2) + ($btn-padding-width * 2) + ($border-width * 2);

$count-height: 18px;
$name-height: 12px;

#transitionGroup {
    &:hover > .status-bucket-item-footer-large {
        visibility: visible;

    }
}

.btn-small{
    @extend .btn-std;
    height:$btn-height-sm;
    font-size: $name-height - 1px;
    font-weight: 600;
}
.btn-large{
    @extend .btn-std;
    height: 5rem;
    width: 8rem;
    border-radius: 5px;
    // height:$btn-height;
}

status-buckets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.status-bucket-border {
    border: 1px solid #d1d1d1;
    padding-bottom: .5rem;

    // &:first-child { // there seems to be a bug with the first-child selector
    //     border-left: none;
    // }
    // &:last-child { // whereas last-child works fine
    //     border-right: none;
    // }
}

.hundred {
    width:100%;
    height:100%
}

.flex-basis-custom-1 {
    flex-basis: 50%;
}

.flex-basis-custom-2 {
    flex-basis: 33.33%;
}

.status-bucket-group-text {
    font-weight: 450;
    color: $theme-light-dark;
    flex: 1 1 50%
}

.status-bucket-container {

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    position: relative;
    // overflow: auto;

    &:first-child {
        margin-bottom: 0.125rem;
        margin-top: 0.125rem;
    }

    .sync-bar .dat-bar {
        display: flex;
        justify-content: ' ';
        width: 50px;
        height: 50px;
    }

    .select-all{
        width: 14px;
        height: 12px;
        // border: 1px solid $theme-second-color;
        // &:hover {
        //     background: $theme-light-color-text !important;
        // }
    }
         .square {
        width: 5px;
        height: 4px;
        background: $theme-second-color;
        margin: 1px;
        float: left;
        
    }          
      

    .status-bucket-group-button{
        @extend .btn;
        @extend .btn-small;
        background: $theme-light-color-text !important;
        color: $theme-second-color !important;

        &:hover {
                color: $theme-light-color-text !important;
                background: $theme-second-color !important;
        }
        &:hover .select-all{
            background: $theme-light-color-text;
        }

        &.active-override {
                background: $theme-second-color !important;
                color: $theme-light-color-text !important;
        }

        &.active-override .select-all{
            background: $theme-light-color-text;
        }

        width:100%;
        
    }

    .status-bucket-item {
        position: relative;

        .status-bucket-item-footer-large {
            visibility: hidden;
            bottom: 0;
            color: $theme-light-color-text;
            font-size: 12px;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            font-weight: 480;
            transition: all 1s ease-out;
        }

        .status-bucket-item-footer {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            bottom: 0;
            color: $theme-second-color;
            font-size: 12px;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            font-weight: 480;
        }
    }

    .status-bucket-item-button{
        // @extend .btn;
        padding: 0 !important;

        img {
            height: 61px;
            top: 1px;
            width: 4.125rem;
            position: absolute;
        }

        .icon{
            display: inline-block;
            // height: 5.3rem;
        }
    }


    &.large-buttons{
        flex-basis: 100%;

        .status-bucket-item-button{
            // @extend .btn;
            // @extend .btn-large;
            // @extend .btn-highlight;
            align-items: center;
            background: $white;
            box-shadow: none;
            display: flex;
            flex-direction: column;
            height: 65px;
            justify-content: flex-end;
            width: 136px;
            transition: opacity 1s ease-out;
            opacity: 1;

            &:hover {
                opacity: 0.5;

                + .status-bucket-item-footer-large {
                    background: $theme-second-color;
                }
            }

            &.active-override {
                + .status-bucket-item-footer-large {
                    visibility: visible;
                    background: $theme-second-color;
                    opacity: 0.85;
                }
                // &:active {
                //     &:hover {
                //         opacity: 1;
                //     }
                // }
            }

            .labels{
                .count{
                    display: block;
                    font-size: $count-height - 2px;
                    line-height: $count-height;

                }
                .friendly-name{
                    display: block;
                    visibility: hidden;
                    height: $name-height;
                    font-size: $name-height - 2px;
                    line-height: $name-height;
                }
            }
        }
    }

    
    &.medium-buttons{
        flex-basis: 50%;
        min-height: 3.5rem;

        .status-bucket-item-button{
            // @extend .btn;
            // @extend .btn-large;
            // @extend .btn-highlight;
            align-items: center;
            background: $white;
            box-shadow: none;
            display: flex;
            flex-direction: column;
            height: 3rem;
            justify-content: flex-end;
            width: 6.5rem;

            &:hover {

                + .status-bucket-item-footer {
                    color: $theme-light-color-text;
                    background: $theme-second-color;
                    // height: 0.25rem;
                }
            }

            &.active-override {
                + .status-bucket-item-footer {
                    background: $theme-second-color;
                    color: $theme-light-color-text;
                    // height: 0.25rem;
                }
            }
            .icon {
                            height: 1.75rem;
                            left: 0;
                            margin: 0;
                            position: absolute;
                            top: 3px;
                        }

            .labels{
                position: relative;
                top: -5px;
                .count{
                    display: block;
                    font-size: $count-height - 4px;
                    line-height: $count-height + 30px;
                    color: $theme-second-color;
                    font-weight: 600;
                }

                .friendly-name{
                    display: block;
                    height: $name-height;
                    font-size: $name-height - 2px;
                    line-height: $name-height;
                    

                }
            }
        }
    }
    // &.medium-buttons{
    //     flex-basis: 50%;
    //     min-height: 3.5rem;

    //     .status-bucket-item-button{
    //         align-items: center;
    //         background: $white;
    //         box-shadow: none;
    //         display: flex;
    //         flex-direction: column;
    //         height: 3rem;
    //         justify-content: flex-end;
    //         width: 7rem;

    //         &:hover {

    //             + .status-bucket-item-footer {
    //                 color: $theme-light-color-text;
    //                 background: $theme-second-color;
    //                 height: 0.25rem;
    //             }
    //         }

    //         &.active-override {
    //             + .status-bucket-item-footer {
    //                 background: $theme-second-color;
    //                 color: $theme-light-color-text;
    //                 height: 0.25rem;
    //             }
    //         }

    //         .icon {
    //             height: 1.75rem;
    //             left: 0;
    //             margin: 0;
    //             position: absolute;
    //             top: 3px;
    //         }

    //         .labels{
    //             position: relative;
    //             top: -5px;

    //             .count{
    //                 display: block;
    //                 font-size: $count-height - 2px;
    //                 line-height: $count-height;

    //             }
    //             .friendly-name{
    //                 display: block;
    //                 height: $name-height;
    //                 font-size: $name-height - 2px;
    //                 line-height: $name-height;

    //             }
    //         }
    //     }
    // }
    &.small-buttons{
        flex-basis: 50%;
        @extend .tiny-buttons;
    }
    &.tiny-buttons{
        flex-basis: 50%;
        .status-bucket-item-button{
            //@extend .btn;
            @extend .btn-large;
            @extend .btn-tiny-highlight;
            display: flex;
            height: 50px;
            width: 50px;
            margin: 0px 5px 0px 5px;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            .labels{
                // vertical-align: top;
                display: inline-block;
                // text-align:left;
                // height: $icon-height;
                .count{
                    // display: block;
                    // height: $count-height;
                    font-size: 11px;
                    // line-height: $count-height;

                }
                .friendly-name{
                    display: block;
                    height: $name-height;
                    font-size: $name-height - 2px;
                    line-height: $name-height;

                }
            }
        }
    }
}

.group-line {
    border-top: 1px solid $theme-second-color;
    width: 70%;
    margin: 0 auto;
    position: relative;
    top: -25px;
    z-index: -1;
}

// .parent-container.child-open {

//     status-buckets {

//         .flex-b-50 {
//             // max-width: 100% !important;
//             }

        
//     }
// }
