div.form-group {
    margin-bottom: 0.2rem;
}

p.form-control-static{
    padding-bottom: 0;
    padding-top: 0;
    word-break: break-all;
}

.break-comments{
    word-wrap: break-word;
}

.invoice-attachment{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    span.hint {
        position: absolute;
        margin-left: 5px;
    }
}

.whole-invoice{
    margin-top: 5px;
}

/* iPad Portrait and Landscape */
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .claim-header > div {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/* Galaxy Tab Portrait and Landscape */
@media
  (min-device-width: 800px)
  and (max-device-width: 1280px) {

    .claim-header > div {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen
  and (min-width: 576px)
  and (max-width: 1200px) {
    .claim-header > div {
        flex: 0 0 50%;
        max-width: 50%;
    }
}