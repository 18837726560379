ul.term-list {
    border: $component-border;
    background: $component-background-color;
    border-radius: 0 0 $border-radius $border-radius;
    font-size: $suggestion-list-font-size;
    list-style: none inside;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 100; 
    
    &.big-list-height {
        max-height: $suggestion-list-height-big;
    }

    &.default-list-height {
        max-height: $suggestion-list-height-default;
    }


    button.btn-link {
        cursor: pointer;
    }
    
    li {
        border-bottom: $component-border;
        overflow: hidden;
        padding: 0.3em;
        

        .sub-label {
            font-size: 10px;
        }

        &:first-child {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        &:last-child {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }

}
.autocomplete {
    position: relative;
    display:block;
    label{
        width:100%;
    }
}

.item-disabled{
    pointer-events:none; //This makes it not clickable
    opacity:0.6;         //This grays it out to look disabled
}
