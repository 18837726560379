// ----------------------------------------------------------
// This file should contain all the styles that need to be themed based on
// the current app. They should only reference variables defined in the app theme
// file (e.g. in the _ordrd.variables.scss file)
// ----------------------------------------------------------

@import "./base.styles";
@import "./boxes";
@import "./icons";
@import "./miscellaneous";
@import "./table";
@import "./tabs";
@import "./transaction";
@import "./ag-grid";
@import "./buttons";

.logo-main {
    @include spritesheet-image($logo-main);
}
.spenda-logo-main {
    @include spritesheet-image($spenda-logo-main);
}

legend {
    border-bottom: thin solid $theme-color-border-bottom;
    height:$legend-height;
    margin-bottom: 0px;
    font-size: $legend-font-size;
}

// Override bootstrap colours based on current theme
.custom-control-input {
    &:checked ~ .custom-control-indicator {
        background-color: $theme-color;
    }

    &:focus ~ .custom-control-indicator {
        // the mixin is not used here to make sure there is feedback
        box-shadow: 0 0 0 .075rem rgba(255, 255, 255, 1), 0 0 0 .2rem $theme-color !important;
    }
}

.form-control:focus {
    border-color: $theme-color !important;
    box-shadow: 0 1px 1px $theme-color 0 0 8px lighten($theme-color, 15%) !important;
}

// Only apply to active under the root router so it doesn't apply
// to the modal active which activates that overlay
#root-router .active, .modal-body .active {
    &:not(.active-override){
        background-color: $theme-second-color !important;
        border-color: $theme-second-color !important;
        color: $theme-light-color-text;
    }
}

.uppercase {
    text-transform: uppercase;
}

.font-size-tiny{
    font-size: 0.60rem;
}
.font-size-small{
    font-size: 12.5px;
}
.font-size-standard{
    font-size: 14px;
}
.font-size-large{
    font-size: 1.2rem;
}
.font-size-larger{
    font-size: 1.4rem;
}


h1 {
    font-size: 1.5rem;
}
h2 {
    font-size: 1.25rem !important;
}
h3 {
    font-size: 1rem;
}