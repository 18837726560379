#settings-dashboard {
    .setting-section {
        @extend .col-sm-6;
        @extend .col-xl-4;
    }

    .section-header {
        @extend .h5;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        padding-bottom: 0.25em;
        border-bottom: 1px $gray solid;
    }

    .group {
        margin-bottom: 1em;
    }

    .heading {
        font-size: 1.1em;
    }

    .help {
        @extend .form-control-static;
        font-size: 0.9em;
    }
}