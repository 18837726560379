.total-container{
    text-align: end;
    border-top: 1px solid #EEE;
    margin-bottom: 20px;
    padding-bottom: 9px;

    .sub{
        .num{
            font-size: 18px !important;
            font-weight: 600;
        }
    }

    .total{
        font-size: 20px !important;
        font-weight: 600;
        .bord{
            border-top: 1px solid black;
        }
    }
}