.nav-tabs{
    border:none;
    .nav-link{
        font-weight: 500;
        text-align: center;
        @extend .theme-border-color;
        border-width:$border-width;
        // border-color:$greyed-border-color;
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        &:not(.active){
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            border-color: $greyed-border-color;
            background-color: white;
            color:black;
            @include transition-multi(0.8s,color,background);
        }
    }
}

.tab-content{
    @extend .px-2;
    // background-color: $greyed-background-color;
    .summary-header-row{
        background-color: $page-background-color;
    }
}
