.notification {
	font-weight: bold;
}

.notification-read {

}

.notification, .notification-read{
    border-bottom: 1px solid #eee;
	display: flex;
	margin: 3px 1rem;

	.notification-check{
		flex-grow: 1;
		align-self: center;
    	margin-right: 10px;
	}

	.notification-message{
		flex-grow: 25;

		i {
			margin-right: 5px;
		}
	}

	.notification-action{
		align-self: center;
		flex-grow: 1;
    	margin-left: 10px;
		text-align: right;

		span {
			cursor: pointer;
		}
	}

}

