
a.list-item-summary, .list-item-summary {
    text-decoration: none;

    &.active-override{
        .row{
            @extend .theme-border-color;
            @extend .theme-border-highlight;
            padding-bottom:$border-width;
            cursor: pointer;
        }
    }
    &.previously-active {
        .row{
            @extend .theme-second-border-color;
            padding-bottom:$border-width;
        }
    }
    .row{
        background-color:white;
        border-color: $greyed-border-color;
        border-radius: $border-radius;
        border-style:solid;
        border-width:$border-width;
        color:black;
        // @include transition-multi(0.2s,border,background,padding);
    }
    .row:hover{
        @extend .theme-border-color;
        color: $theme-dark-color;
        // cursor: pointer;
    }
}

.match-and-link-list-item-summary
{
        background-color:white;
        border-color: $greyed-border-color;
        border-radius: $border-radius;
        border-style:solid;
        border-width:$border-width;
        // border-bottom-width: $border-radius;
        color:black;
        @include transition-multi(0.2s,border,background,padding);

}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-header-row{
    font-weight:500;
    // padding-top: 7px;
    @extend .no-gutters;
}

.text-align-center{
    text-align: center;
}

.text-heading-align {
    position: relative;
    left: 30px;
}

.summary-row{
    display: flex;
    justify-content: flex-start;
    // @extend .align-items-center;
    @extend .no-gutters;
    @extend .mb-1;
    &:last-child(){
        @extend .mb-0;
    }
}

.col-icon{
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 40px;
    width:40px;

    &.center-with-text {
        vertical-align: bottom;
    }
}

.col-icon-mini{
    content: " ";
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height:18px;
    position: absolute;
    width:18px;
}

.pin-btn{
    padding:0px;
    &:not(.active-override),
    &:not(:hover){
        .checked{
            display:none;
        }
    }
    &.active-override,
    &:hover{
        span{
            color:white;
        }
        .checked{
            display: inline-block;
        }
    }
}

.max-width-col{
    max-width: 6%;
}

.col-mini-svg{
    flex-basis: 5%;
    // padding-right: 10px;
}

.col-mini-cir{
    max-width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-maxi-cir{
    max-width: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-maxi-svg{
    flex-basis: 3%;
    padding-right: 10px;
    margin-right: 10px;
}

.summary-row .row,
.summary-header-row .row{
    @extend .no-gutters;
    min-height: 40px;
    // @extend .px-2;
    // [class^="col-"]{
        //     // overflow:hidden;
        // }
    }

    .text-ellipse{
        overflow: hidden !important;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .pinned-checkbox{
        input[type="checkbox"]{
        display:none;
        &:checked ~ .checked{
            display:inline-block;
        }
        &:checked ~ .unchecked{
            display:none;
        }
        &:not(:checked) ~ .unchecked{
            display:inline-block;
        }
        &:not(:checked) ~ .checked{
            display:none;
        }
    }
    .unchecked, .checked{
        @extend .font-size-larger;
        color: $gray-lighten;

    }
}

.arch{
    color: $theme-second-color;
    font-size: 20px;
    padding:10px 20px;
    text-align: center;
}

.font{
    color: $theme-second-color;
}

div.row > compose {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.row--grey {
    background: #99B1B9 !important;
}