@mixin spritesheet-image($map) {
    background-image: map-get($map, "sheet-url");
    background-position: map-get($map, "background-pos");
	background-repeat: no-repeat;
    display: inline-block;
    height: map-get($map, "height");
    width: map-get($map, "width");

    @media all and (-webkit-min-device-pixel-ratio: 1.5), all and (-moz-min-device-pixel-ratio: 1.5), all and (-o-min-device-pixel-ratio: 3/2), all and (min-device-pixel-ratio: 1.5) {
        background-image: map-get($map, "sheet-url-x2");
        background-size: map-get($map, "image-width-x2") map-get($map, "image-height-x2");
        background-position: map-get($map, "background-pos-x2");
    }
}