.inventory-soh-header
{
    background-color: $header-background-color-2;
    color: $white;
    height: 4.25em;
    border-radius: 5px;
}

.table-header--primary span {
    font-size:1.27rem;
}

.soh-body span {
    font-size: 13px;
}

.inventory-soh-cell
{
    // height:3.25em;
    border-radius: 4px;
    color: $black;
    font-size: 1em;

    span {
        font-size: 1.25em !important;
    }

    &--onOrder {
        @extend .inventory-soh-cell;
        border:2px solid $theme-color-wh-calypso;
        // box-shadow:0px 0px 3px $black;
    }
    &--committed {
        @extend .inventory-soh-cell;
        border:2px solid $theme-color-wh-orange;
        // box-shadow:0px 0px 3px $black;
    }
    &--available {
        @extend .inventory-soh-cell;
        border:2px solid $theme-color-wh-green;
        // box-shadow:0px 0px 3px $black;
    }
    &--onHand {
        @extend .inventory-soh-cell;
        border:2px solid $theme-color-wh-saltbox;
        // box-shadow:0px 0px 3px $black;
    }
    &--intransit
    {
        @extend .inventory-soh-cell;
        border:2px solid $theme-color-wh-sandrift;
        // box-shadow:0px 0px 3px $black;
    }
}

.soh-form-control-label
{
    @extend .form-control-label;
    font-size: 11px !important;
    font-weight: 700 !important;
}

.table-scroll
{
    overflow-y:auto;
    overflow-x: hidden;
    max-height: 565px;
}

.soh-icon
{
    position: relative;
    
    &--minus
    {
        @extend .soh-icon;
        left: 3.5em;
        top: -1.2rem;
        color:$white;
    }
    &--equal
    {
        @extend .soh-icon;
        left: 11.55rem;
        // top:-0.5rem;
    }
    

}

.custom-cell-border
{
    background-color: #347e99 !important;

    &--newborder
    {
        border-radius: 5px 0 0 5px;
    }

    &--left
    {
        @extend .custom-cell-border;
        border-left:2px solid #efefef !important;
    }

    &--bgSold
    {
        @extend .custom-cell-border;  
    }

    &--bgAvail
    {
        @extend .custom-cell-border;
        border-radius: 0 5px 5px 0;
    }
}