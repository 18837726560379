#box1{
    width: 200px;
    height: 200px;
    border: 1px solid black;
}

canvas {
    max-width: 100%;
    max-height: 100%;
}

.helper-text{
    font-size: small;
    color: $gray-dif;
    font-style: italic;
}

.commit-stocktake-details {

    date-time-picker + div.form-control-feedback {
        position: absolute;
        right: 4rem;
        bottom: -0.5rem;
        font-size: 14px;
    }
}
