@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

@mixin transition-transform($transition) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}
