.grid-rows {}

.grid-container {
    
    .sizing-row {
        visibility: collapse;
    }

}

thead tr.header-row {

    th {
        background-color: rgba(78,165,197,0.22);
        text-align: center;
        vertical-align: middle;
    }
}

thead tr.soh-header-row{
    th{
        background-color: rgb(77, 165, 197);
        text-align: center;
        vertical-align: middle;
        color:$white;
        height: 4.25em;
        border:none;
    }
}

.item-row {
    td {
        background: #F3F3F3;
        font-size: 0.875rem;
        height: 38px;
        padding: 0;
        text-align: center;
        vertical-align: middle;

        input, select {
            border: 0;
            border-radius: 0;
            padding: 0.25rem;
            text-align: center;
            width: 100%;
            height: 38px;
        }

        ul {
            .btn-link {
                font-size: 0.75rem;
            }
        }

        .btn-link {
            font-size: 1.5rem;
            padding: 0 .375rem;
            &:enabled:focus {
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
            };
            &:disabled {
                cursor: default;
                opacity: 0.25;
            }
        }
        
    }
}