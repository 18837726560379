// Statuses Icons
.status-icon {
    height: 2rem;
    // margin: 1px 0;
    width: 2rem;
}

.new-status-light {
    @extend .status-icon;
    @include claim_status_new_icon($white);
}

.processing-status-light {
    @extend .status-icon;
    @include claim_status_processing_icon($white);
    height: 2.3rem;
}

.sent_as_pdf-status-light {
    @extend .status-icon;
    @include claim_status_sent_as_pdf_icon($white);
    width: 1.8rem;
}

.archived-true {
    @extend .status-icon;
    @include claim_status_processing_icon($theme-second-color);
}

.archived-false {
    @extend .status-icon;
    @include claim_status_processing_icon($black);
}

.sending-status-light {
    @extend .status-icon;
    @include claim_status_sent_as_pdf_icon($white);
    width: 1.8rem;
}

.sent-status-light {
    @extend .status-icon;
    @include claim_status_sent_icon($white);
    height: 1.4rem;
}

.approved-status-light {
    @extend .status-icon;
    @include menubar_approve_white_icon($white);
    height: 1.5rem;
    width: 1.5rem;
}

.rejected-status-light {
    @extend .status-icon;
    @include menubar_reject_white_icon($white);
}

.picking-status-light {
    @extend .status-icon;
    @include inventory-icon($white, $white, $white);
}

.new-status-dark {
    @extend .status-icon;
    @include claim_status_new_icon($theme-second-color);
}

.processing-status-dark{
    @extend .status-icon;
    @include claim_status_processing_icon($theme-second-color);
}

.sent_as_pdf-status-dark{
    @extend .status-icon;
    @include claim_status_sent_as_pdf_icon($theme-second-color);
    width: 1.8rem;
}

.sending-status-dark{
    @extend .status-icon;
    @include claim_status_sent_as_pdf_icon($theme-second-color);
    width: 1.8rem;
}

.sent-status-dark{
    @extend .status-icon;
    @include claim_status_sent_icon($theme-second-color);
    height: 1.4rem;
}

.approved-status-dark{
    @extend .status-icon;
    @include menubar_approve_white_icon($theme-second-color);
    height: 1.5rem;
    width: 1.5rem;
}

.rejected-status-dark{
    @extend .status-icon;
    @include menubar_reject_white_icon($theme-second-color);
    height: 1.5rem;
    width: 1.5rem;
}

.picking-status-dark {
    @extend .status-icon;
    @include inventory-icon($theme-second-color, $white, $white);
}
