.gridContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content min-content ;
    grid-template-areas: 
    "info"
    "options"
    "SupportPhone"
    "SupportEmail"
    "code"
    "switch"
    "description"
    "confirm"
    "helplogging";
    font-family: "Saira Extra Condensed",sans-serif;
}

.info {
    grid-area: info;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 1em;
    padding: 1em; 
    font-size: 20px;
    .subTitle {
        width: 391px;
        text-align: center;
    }
}

.code {
    grid-area: code;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0 auto;
}

.options {
    grid-area: options;
    display: grid;
    grid-gap: 4em;
    grid-template-columns: 1fr auto;
    margin: 0 auto;
    button {
        background-color: #fff !important;
        border-radius: 5px;       
        border: 1px solid #4da5c5 !important;
        padding: 0 !important;
        border-radius: 7px;
        
    } 
    button:focus {
            outline:1px auto #4da5c5 !important;
    }
    button:hover {
        background-color:#e5f8ff !important;
    }
    

}

.switch {
    grid-area: switch;
    display: grid;
    grid-template-columns: 1fr auto;
    width: 50%;
    margin: 0 auto;
    grid-gap: 1em;
    padding: 1em;
    font-size: 20px;
}

.helplogging {
    grid-area: helplogging;
    margin: 0 auto;
    color: #4da5c5;
    font-size: 17px;
    font-weight: 500;
    a:hover {
        cursor: pointer;
      color: #1a93bf !important;
    }
    p {
        color:#4da5c5 !important;
    }
}

.confirm {
    grid-area: confirm;
    margin: 0 auto;
    button {
        font-size: 20px;
    }
}

.description {
    grid-area: description;
    display: grid;
    grid-gap:3em;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    > div{
        text-align: center;
    }
    font-size: 20px;
    p {
        color:#4da5c5 !important;
    }
}

.SupportPhone{
    grid-area: SupportPhone;
    display: grid;
    grid-gap: 0.55em;
    grid-template-columns: 1fr auto;
    margin: 0 auto;
    font-size: 20px;
    .fa {
        color: $gg-background;
    }
}

.SupportEmail {
    grid-area: SupportEmail;
    display: grid;
    grid-gap: 0.55em;
    grid-template-columns: 1fr auto;
    margin: 0 auto;
    font-size: 20px;
    padding-bottom: 10px;
    .fa {
        color: $gg-background;
    }
}

 .gridContainer .info > div {
    display: flex;
    justify-content: center;
}


.gridContainer input {
    text-align: center;
    font-size: 20px !important;
    letter-spacing: 7px;
}

.info button {
    width: 6em;
    border-radius: 7px !important;
    background-color: #4da5c5;
    border:1px solid #4da5c5;
    color: #fff;
    font-size: 20px;
}

#setTwoStepAuth, #helpLogging .modal-content {
    min-width: 485px !important;
    min-height: 375px !important;
}

