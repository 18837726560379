#trumbowyg-editor-component{
    &.view-mode{
        .trumbowyg-button-pane{
            display:none;
        }
    }
    
    .trumbowyg-button-pane{
        z-index:2;
    }
}
