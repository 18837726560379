$header-text-color: white;

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

sticky-footer{
    position:fixed;
    width:100%;
    bottom:0;
    z-index:$header-footer-z-index;
}

.footer {
    width: 100%;
    font-size: 0.8rem;
    
    /* Set the fixed height of the footer here */
    height: $sticky-footer-height;
    line-height: $sticky-footer-height; /* Vertically center the text there */
    background-color: $footer-background-color;
    border-top: 1px darken($footer-background-color, $border-darken-amount) solid;

    a {
        margin: 0 0.3rem;
    }

    /* Reset line height for multi-line items */
    .btn-group {
        line-height: normal;
    }
}

sticky-footer-links {
    a {
        color: #767B91;
        &:hover {
            color: #F2EFE9;
            text-decoration: none;
        }
    }
}
