$amended-color: #f0ad4e;

// The following two classes are applied on tbody and tr respectively in order to make the table scrollable
// and make the row fit completely to the tbody width.
.overflow-util-tbody {
    display: block;
    overflow: auto;
}

.overflow-util-trow {
    display: table;
    width: 100%;
}

.table-responsive {

    overflow: visible;

    .form-group {
        margin-bottom: 0;
    }

    .repeater-element {
        border: 1px solid #ADADAD;
        margin-top: -1px;
        padding: 0rem 1rem;

        &:first-child {
            padding-top: 0;

            .column-label {
                border-color: #ADADAD !important;
            }
        }

        &:last-child {
            .column-field {
                border: 0 !important;
            }
        }

        inventory-selector {
            div.input-group {
                justify-content: center;
            }
        }

        textarea.description{
            overflow-x: hidden;
            overflow-y: scroll;
        }

    }

    .row {

        .column-label{
            margin-top: -1px;
            text-align: center;
        }

        .column-field {

            @include media-breakpoint-down(sm) {
                order:0 !important;
            }
            padding: 5px 1px;
            text-align: center;

            &.amended {
                border-top: 2px solid $amended-color !important;

                &.expanded {
                    border-bottom: 0 !important;
                }

                &.collapsed {
                    border-bottom: 2px solid $amended-color !important;
                }

                &:nth-child(2) {
                    border-left: 2px solid $amended-color !important;
                }

                &:nth-last-child(2) {
                    border-right: 2px solid $amended-color !important;
                }

                + .line-comment-attachments {
                    border-bottom: 2px solid $amended-color !important;
                    border-left: 2px solid $amended-color !important;
                    border-right: 2px solid $amended-color !important;
                }
            }

            &:nth-child(2) {
                padding-left: 5px;
            }

            &:nth-last-child(2) {
                padding: 10px 0 0 5px;
            }

            &.claim-line-btns {
                text-align: right;
                padding-right: 10px;
            }

            div.form-control.of-hidden{
                padding: 0;
            }

            input.form-control {
                border-radius: 0;
            }

            input.form-control[type='number'] {
                padding: 0.5rem 0.1rem;
                text-align: right;
            }

            select.form-control{
                padding: 0 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
        }

        .line-comment-attachments{

            div[name='reason'] {
                text-align: center;
            }
        }

        claim-line-buttons {

            i.fa {
                font-size: .75rem;
                cursor: pointer;
            }
        }

        @media (max-width: 767px) {
            .col-sm-1, .col-sm-2, .col-sm-3 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        /* iPad */
        @media only screen
        and (min-device-width: 769px)
        and (max-device-width: 1024px)
        and (-webkit-min-device-pixel-ratio: 2) {

            .col-sm-1, .col-sm-2, .col-sm-3 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        /* Galaxy */
        @media
        (min-device-width: 800px)
        and (max-device-width: 1280px) {

            .col-sm-1, .col-sm-2, .col-sm-3 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

.original-line {
    p {
        text-align: center;

        &.crossed-out {
            color: $amended-color;
            text-decoration: line-through;
        }
    }

}

div.original-line.form-control-static{
    margin: 0 auto;
}