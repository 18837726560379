.question-container {
    @extend .row;
    @extend .mb-1;
    @extend .mb-xl-3;

    .question {
        @extend .col-12;
        @extend .mb-1;
    }

    .answer {
        @extend .col-12;
        @extend .ml-2;
    }
}