
@import "./parent-child-list";

.parent-child-legend{
    height:$legend-height;
    @extend .mb-0;
}
.parent-container {
    
    @extend .col-xl-12;
    @extend .col-lg-12;
    // -webkit-transition: all 0.3s ease-out;
    // transition: all 0.3s ease-out;

    
    &.child-open {
        @extend .d-none;
    }

    &.child-closed {
        @extend .col-12;
    }

}

.child-container {
    &.open {
        @extend .col-xl-12;
        @extend .col-lg-12;
        @extend .col-md-12;
        @extend .col-sm-12;
        @extend .col-12;
    }
    &.closed {
        @extend .d-none;
    }
}

.parent-container,
.child-container{
    border-radius:0px !important;
    border-top:0px !important;
    border-bottom:0px !important;
}

.results-container {
    @extend .list-group;
    margin-top: 0.5rem;
}

.results-item {
    display: block;

    @extend .list-group-item;
    @extend .list-group-item-action;

    .primary-info {
        overflow: hidden;
        font-weight: bold;
    }

    .secondary-info {
        overflow: hidden;
        font-size: 0.9rem;

        .next-to-status {
            margin-top: 5px;
            text-align: right;
        }
    }
}



.parent-child-search-area {
    margin: 1rem 0;
}