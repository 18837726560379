.adaptorDatTypeHeading {
    font-weight: bold;
}

.datTypeSetupContainer {
    margin: 0.5em 0;
    @include greyed-background();

    .row {
        margin: 0.5em 0;
    }

    .not-available {
        color: $red;
    }
}

#adaptorSelector {
    // Allow the "Add" button to appear inline
    display: inline-block;

    .dropdown-toggle {
        // Given the user is going to have to select an item with an image,
        // force a minimum height to reduce the change of having to resize
        // the button on selection anyway
        min-height: 3.2em;
        @extend .image-and-text-button;
    }
}

.adaptorImage {
    max-width: 3em;
    max-height: 34px;
}

.scheduledTaskInfoContainer {
    @include greyed-background();
    margin: 0.5em 0;
    padding: 0.5em;
}