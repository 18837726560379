.bar-box {
  border: 1px solid #fff;
  color:#fff;
  font-size: 20px;
  padding:10px 20px;
  text-align: center;
  text-shadow:4px 4px 0 rgba(0,0,0,0.1);
}

@mixin bar-box-color($color) {
    background:$color;
        &:hover {
            background-color: darken($color, 10%);
            border-color: darken($color, 10%);
        }
}

.bar-box-disabled {
    background: #ddd !important;
    pointer-events: none;

    span.fa{
        cursor: not-allowed;
    }
}

/* Colours for each box */
.bar-box1 { @include bar-box-color(#1abc9c); }
.bar-box2 { @include bar-box-color(#3498db); }
.bar-box3 { @include bar-box-color(#9b59b6);}
.bar-box4 { @include bar-box-color(#34495e);}
.bar-box5 { @include bar-box-color(#f1c40f);}
.bar-box6 { @include bar-box-color(#e67e22);}

.bar-container {
  display:flex;
  justify-content: center;
}

.bar-box-message {
    text-align: center;
}