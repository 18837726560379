// @import "./app_features/app_features";
@import "./component_features/component_features";
@import "./resources/resources";
@import "./shared/shared";

.watermarked {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::before {
        position: absolute;
        left: -50%;
        top: -50%;

        display: block;
        width: 150%;
        height: 150%;

        transform: rotate(-45deg);
        content: attr(data-watermark);

        opacity: 0.7;
        line-height: 4em;
        letter-spacing: 2px;
        color: #e6e6e6;
        font-size: 2em;
    }
}

.custom-select{
    appearance: none;
    // background-color: $theme-second-color;
    // border-radius: 8px;
    // color: $page-background-color;
    // width:100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand{
        display: none;
    }
}
