/* In SASS you can't currently @extend a class within a media query, so we can't
   use the theme styles for doing the colours, we need to include a version of this
   file in each app which is why this is in the styles/base folder */

$row-border-darken-amount: 7;

@mixin tableStyle($breakpoint) {
    @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
        .repeater-element:nth-child(odd) {
            .column-field {
                background-color: $theme-light-color;

                + .line-comment-attachments {
                    background-color: $theme-light-color;
                }
            }

        }

        .repeater-element:nth-child(even) {
            .column-field {
                background-color: #F9F9F9;

                + .line-comment-attachments {
                    background-color: #F9F9F9;
                }
            }
        }

        .column-label {
            display: none;
        }

        .repeater-element:first-child {
            .column-label {
                display: inline-block;
                border-style: solid;
                border-color: $theme-color;
                border-width: 1px 0;
            }
        }

        .repeater-element:last-child {
            .column-field {
                border-style: solid;
                border-color: $theme-color;
                border-width: 0 0 1px 0;
            }
        }
    }
}

@mixin cardStyleRaw() {
    .table-row {
        background: $theme-light-color;
        border: 1px $theme-color solid;
        border-radius: 0.5rem;
        margin: 0.5rem 0;
        

        >.row {
            margin: 0.5rem;
        }
    }
}

@mixin cardStyle($breakpoint) {
    @media (max-width: map-get($grid-breakpoints, $breakpoint) - 1px) {
        @include cardStyleRaw();
    }
}

.responsive-card-table {
    margin: 0.5rem 0;

    .inline-button-container {
        >button {
            margin: 0.25rem;
        }
    }

    .column-label {
        @extend .form-control-label;
    }

    .column-label + .column-field {
        order: 1;
    }

    .no-results {
        font-size: 1.25rem;
        color: #999;
        border-style: solid;
        border-color: $theme-color;
        border-width: 1px 0;
        background: $theme-light-color;
        margin: 0 -15px;
    }

    /* The following styles are the screen sizes we want to have cards at,
       however the functions accept the breakpoint (which is the one above) */
    .cards-none {
        .inline-button-container {
            @extend .d-flex;
            @extend .justify-content-end;
        }

        // .column-field {
        //     @extend .flex-last;
        // }

        @include tableStyle("xs");
    }

    .cards-xs {
        .inline-button-container {
            @extend .d-flex;
            @extend .justify-content-sm-end;
        }

        // .column-field {
            // @extend .flex-sm-last;
        // }

        .extra-column-field {
            // @extend .flex-sm-last;
            display: flex;
            flex: 0 0 100%;
            order: 2;
        }

        .pre-column-field {
            // @extend .flex-sm-last;
            display: flex;
            flex: 0 0 100%;
            order: 0;
        }

        @include tableStyle("sm");
        @include cardStyle("sm");
    }

    .cards-sm {
        .inline-button-container {
            @extend .d-flex;
            @extend .justify-content-md-end;
        }

        // .column-field {
        //     @extend .flex-md-last;
        // }

        .extra-column-field {
            // @extend .flex-sm-last;
            display: flex;
            flex: 0 0 100%;
            order: 2;

        }

        @media (max-width: 767px) {
            .extra-column-field {
                display: block;
            }
        }

        .pre-column-field {
            // @extend .flex-sm-last;
            display: flex;
            flex: 0 0 100%;
            order: 0;
        }

        @include tableStyle("md");
        @include cardStyle("md");
    }

    .cards-md {
        .inline-button-container {
            @extend .d-flex;
            @extend .justify-content-lg-end;
        }

        // .column-field {
        //     @extend .flex-lg-last;
        // }

        @include tableStyle("lg");
        @include cardStyle("lg");
    }

    .cards-lg {
        .inline-button-container {
            @extend .d-flex;
            @extend .justify-content-xl-end;
        }

        // .column-field {
        //     @extend .flex-xl-last;
        // }

        @include tableStyle("xl");
        @include cardStyle("xl");
    }

    .cards-xl {
        @include cardStyleRaw();
    }
}

fieldset.has-danger {
    legend, .no-results, .column-label {
        border-color: $red !important;
        color: $red !important;
    }

    .column-field, .table-row {
        border-color: $red !important;
    }
}

.helpful-row.has-danger {
    .col-label, .col-input {
        border-color: $red !important;
        color: $red !important;
    }
}

table {

    &.table {
        i.fa {
            cursor: pointer;

            // &:hover, &:focus{
            //     color: $light-blue;
            // }
        }
    }

    &.table-input-data {
        .form-control{
                padding: .5rem .25rem
        }
    }
}

@for $i from 1 through 12 {
    .table .col-#{$i} {
        width: percentage($i / 12);
    }
}

.table {
    .table-row{
        background-color:white;
        border-color: $greyed-border-color;
        border-radius: $border-radius;
        border-style:solid;
        border-width:$border-width;
        color:black;
        min-height: 40px;

        &:hover {
            border-color: $theme-second-color;
            color: $theme-dark-color;
        }
    }
}

th {
    font-weight: 600;
    line-height: 1;
}

thead tr.table-header--primary{
    th{
        background-color: rgb(77, 165, 197);
        text-align: center;
        vertical-align: middle;
        color:$white;
        // height: 4.25em;
        border:none;
    }

    th.add-new-pob-btn {
        border-right: 1px solid gray;
        background-color: #3e86a0;
        border-top-right-radius: 2px;
        border-top: 1px solid gray;
        box-shadow: inset 0px 0px 3px 0px grey;
    }
}
