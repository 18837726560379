div.modify-selected {
    fieldset {
        padding: 1rem;
        border: 1px solid lightgray;
        background: $white;

        legend {
            border: none;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            width: auto;
        }
    }
}
