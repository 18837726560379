/*
 * General: Miscellaneous
 * ----------------------
 */

// Description Blocks
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
  &.margin-bottom {
    margin-bottom: 25px;
  }
  > .description-header {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
  }
  > .description-text {
    text-transform: uppercase;
  }
}

//Disabled!
[class^="bg-"].disabled {
  @include opacity(.65);
}

// Text colors
.text-red {
  color: $red !important;
  // display:inline-block;
  white-space: nowrap;
  overflow:hidden !important;
//   text-decoration: underline !important;
  text-overflow: ellipsis;
  &.child-open {
    width:120px;
  }
  &.child-closed {
    width:260px;
  }
  // &:hover:not(:disabled){
  //   color: $theme-second-color !important;
  // }
}

.text-yellow {
  color: $yellow !important;
}

.text-blue {
  color: $blue !important;
}

.text-black {
  color: $black !important;
}

.link-muted {
  color: darken($gray, 30%);
  &:hover,
  &:focus {
    color: darken($gray, 40%);
  }
}

.link-black {
  color: #666;
  &:hover,
  &:focus {
    color: #999;
  }
}

// Hide elements by display none only
.hide {
  display: none !important;
}

// Remove box shadow
.no-shadow {
  box-shadow: none !important;
}

// Unstyled List
.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-group-unbordered {
  > .list-group-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

// Remove border radius
.flat {
  @include border-radius(0,0,0,0); //!important;
}

.text-bold {
  &, &.table td, &.table th {
    font-weight: 700;
  }
}

.text-sm {
  font-size: 12px;
}

.text-tiny {
    font-size: 9px;
}

// _fix for sparkline tooltip
.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}

// Gradient Background colors
.bg-teal-gradient {
  @include gradient($teal, $teal, lighten($teal, 16%), "!important"); //!important;
  color: #fff;
}

.bg-light-blue-gradient {
  @include gradient($light-blue, $light-blue, lighten($light-blue, 12%), "!important"); //!important;
  color: #fff;
}

.bg-blue-gradient {
  @include gradient($blue, $blue, lighten($blue, 7%), "!important"); //!important;
  color: #fff;
}

.bg-aqua-gradient {
  @include gradient($aqua, $aqua, lighten($aqua, 7%), "!important"); //!important;
  color: #fff;
}

.bg-yellow-gradient {
  @include gradient($yellow, $yellow, lighten($yellow, 16%), "!important"); //!important;
  color: #fff;
}

.bg-purple-gradient {
  @include gradient($purple, $purple, lighten($purple, 16%), "!important"); //!important;
  color: #fff;
}

.bg-green-gradient {
  @include gradient($green, $green, lighten($green, 7%), "!important"); //!important;
  color: #fff;
}

.bg-red-gradient {
  @include gradient($red, $red, lighten($red, 10%), "!important"); //!important;
  color: #fff;
}

.bg-black-gradient {
  @include gradient($black, $black, lighten($black, 10%), "!important"); //!important;
  color: #fff;
}

.bg-maroon-gradient {
  @include gradient($maroon, $maroon, lighten($maroon, 10%), "!important"); //!important;
  color: #fff;
}

//Description Block Extension
.description-block {
  .description-icon {
    font-size: 16px;
  }
}

//Remove top padding
.no-pad-top {
  padding-top: 0;
}

//Make position static
.position-static {
  position: static !important;
}

//List utility classes
.list-header {
  font-size: 15px;
  padding: 10px 4px;
  font-weight: bold;
  color: #666;
}

.list-seperator {
  height: 1px;
  background: $box-border-color;
  margin: 15px 0 9px 0;
}

.list-link {
  > a {
    padding: 4px;
    color: #777;
    &:hover {
      color: #222;
    }
  }
}

//Light font weight
.font-light {
  font-weight: 300;
}

//User block
.user-block {
  @include clearfix();
  img {
    width: 40px;
    height: 40px;
    float: left;
  }
  .username,
  .description,
  .comment {
    display: block;
    margin-left: 50px;
  }
  .username {
    font-size: 16px;
    font-weight: 600;
  }
  .description {
    color: #999;
    font-size: 13px;
  }
  &.user-block-sm {
    img {
      @extend .img-sm;
    }
    .username,
    .description,
    .comment {
      margin-left: 40px;
    }
    .username {
      font-size: 14px;
    }
  }
}

//Image sizes
.img-sm,
.img-md,
.img-lg {
  float: left;
}

.img-sm {
  width: 30px !important;
  height: 30px !important;
  + .img-push {
    margin-left: 40px;
  }
}

.img-md {
  width: 60px;
  height: 60px;
  + .img-push {
    margin-left: 70px;
  }
}

.img-lg {
  width: 100px;
  height: 100px;
  + .img-push {
    margin-left: 110px;
  }
}

// Image bordered
.img-bordered {
  border: 3px solid $gray;
  padding: 3px;
}

.img-bordered-sm {
  border: 2px solid $gray;
  padding: 2px;
}

//General attachemnt block
.attachment-block {
  border: 1px solid $box-border-color;
  padding: 5px;
  margin-bottom: 10px;
  background: #f7f7f7;

  .attachment-img {
    max-width: 100px;
    max-height: 100px;
    height: auto;
    float: left;
  }
  .attachment-pushed {
    margin-left: 110px;
  }
  .attachment-heading {
    margin: 0;
  }
  .attachment-text {
    color: #555;
  }
}

.connectedSortable {
  min-height: 100px;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-highlight {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
}

.full-opacity-hover {
  @include opacity(.65);
  &:hover {
    @include opacity(1);
  }
}

// Charts
.chart {
  position: relative;
  overflow: hidden;
  width: 100%;
  svg,
  canvas {
    width: 100% !important;
  }
}

// flex utilities
.flex-b-50 {
  flex: auto;
  overflow-x: auto
}

// text

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
