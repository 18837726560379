
.transaction-details-column {
    @include make-col-ready();

    @include media-breakpoint-up(xs) {
        @include make-col(12);
    }
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(md) {
        @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(6);
    }
    @include media-breakpoint-up(xl) {
        @include make-col(6);
    }
}

.transaction-details {
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
    padding-bottom: 9px;
}

.break-comments{
    word-wrap: break-word;
}

.simple-linked-transaction-list{
    @extend .simple-payment-list;
}