// ----------------------------------------------------------
// This file contains classes that can be @extend by other classes
// to inherit theme colours, such as the themed background color.
// ----------------------------------------------------------
.theme-background-color {
    background-color: $component-background-color;
}

.theme-second-background-color {
    background-color: $theme-second-color;
}

.theme-background-color-darkened-x1 {
    background-color: darken($theme-second-color, 5%);
}

.theme-background-color-darkened-x2 {
    background-color: darken($theme-color, 8%);
}

.theme-light-background-color {
    background-color: $theme-light-color;
}

.theme-text-color {
    color: $theme-color-text;
}

.theme-border-color {
    border-color: $theme-second-color;
}

.theme-border-highlight {
    background-color: $active-row-background-color;
}

.theme-border-text-color {
    border-color: $theme-color-text;
}

.theme-second-border-color {
    border-color: $theme-light-color;
}

.theme-icon-background-color {
    background-color: $page-background-color;
}