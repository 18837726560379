transaction-queue{
    display:block;
}
#modifySelectedButton {
    height: 38px;
    margin-left: 0.75rem;
}
.z-index-highest {
    z-index: 500;
}
.actions-row{
    display: flex;
    justify-content: space-between;
    // padding: 3rem;

    modify-selected{
        label{
            @extend .col-12;
            padding-left:0px;
            padding-right:0px;
        }
    }
    label{
        font-size:small;
        width:100%;
    }
}

paging-footer{
    background-color: $page-background-color;
    // padding-top:0.25rem;
    // padding-bottom:0.25rem;
}

.top-controls,
.tab-controls{
    background-color: $page-background-color;
    // z-index:$scrollbox-sticky-z-index - 20;
}

.transaction-queue-dashboard {
    z-index:$scrollbox-sticky-z-index - 10;
    background-color: $page-background-color;
    display: block;
    position: relative;
    padding-bottom: 60px; // Fixed Pagination Controls Gap
    .tab-header{
        z-index:$scrollbox-sticky-z-index - 20;
    }
    .tab-content{
        z-index:$scrollbox-sticky-z-index - 20;
        display: block;
        position: relative;
    }
}

.no-link {
    display: none;
}

@media (min-width: 1200px) {
    .no-link {
        display: block;
    }
}


