$side-menu-text:'Saira Extra Condensed', sans-serif;

#side-menu {
    @extend .theme-text-color;
    background-color:$greyed-background-color;
    border-right-width: $thin-border-width;
    border-right-style: solid;
    color: $theme-color !important;
    position: fixed;
    top: 0;
    height: calc(100vh - #{$sticky-footer-height});
    z-index: $header-footer-z-index + 50;

    button.side-menu-tab {
        border-radius: 13px 0 0 13px;
        background-color: #555;
        border: 1px solid #555;
        width: 15px;
        height: 25px;
        position: absolute;
        right: 0;
        top: 0;
        outline-style: none;
        i {
            bottom: 0;
            display: block;
            height: 0.725rem;
            position: relative;
            right: 3px;
            width: 0.35rem;

            &.side-menu-tab--closed {
                transform:rotate(180deg);
            }
        }
    }

    &.side-menu-open {
        display: block;
        width: 10rem;

        .icon.home {
            @include home-icon("white");
            margin:0 auto;

        }

        .side-menu-text {
            font-family: $side-menu-text;
            display: inline-block;
        }

    }

    &.side-menu-closed {
        width: 5rem;

        .icon.home {
            @include home-icon("white");
        }

        @include media-breakpoint-up(md) {
            .side-menu-text {
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .side-menu-item-container{

        display: block;
        padding-top: 5px;
        padding-bottom: 5px;

        &.has-children{
            .menu-item-parent{
                display: inline-block;
                padding-right: 3px;
            }
        }
        .menu-item-parent{
            padding-left:$side-menu-padding-left;
            padding-right:$side-menu-padding-right;
            align-items: center;
            justify-content: space-between;

            > a:first-child {
                align-items: center;
                text-decoration: none;
            }

            &:hover {
                background-color: $gray-darken;
            }

            &.active-item {
                @extend .theme-border-text-color;
                background-color: #fff !important;
                border: 1px solid $theme-color;
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
                border-right: none;
                display: flex !important;
                margin-left: 10px;
                margin-right: -1px;
            }
        }

        .menu-item-child{
            .side-menu-icon{
                .icon {
                    width: 25px;
                    height: 25px;
                }
            }

            &:first-child{
                .child-branch {
                    height: 14px;
                    top: 0px;
                }
            }

            .child-branch {
                border-left: 2px solid #4EA5C5;
                border-bottom: 2px solid #4EA5C5;
                height: 27px;
                position: relative;
                top: -14px;
                width: 10px;
            }

            &.active-child {
                @extend .theme-border-text-color;
                background-color: #fff !important;
                border: 1px solid $theme-color;
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
                border-right: none;
                display: flex !important;
                //margin-left: 10px;
                margin-right: -1px;
                width: 125px;
            }
        }
    }

    // .side-menu-add-modules {
    //     // fix #1264 - Hidden the "add module" button on side menu. 
    //     visibility: hidden;
    //     // min-height: 25rem;
    //     .puzzle{
    //         @extend .puzzle-white;
    //         height: 20px;
    //         width: 20px;
    //     }
    // }

    a {
        @extend .theme-text-color;

        &.menu-item-child:hover {
            @extend .theme-text-color;
            background-color:$gray-darken;

            
            // &+.parent-toggle {
            //     background-color:$gray-darken;
            // }
        }

        &.active-item,
        &.menu-item-parent.collapsed.child-active{
            // @extend .theme-border-text-color;
            // display: flex !important;
            // border: 1px solid $theme-color;
            // background-color: #fff !important;
            // margin-left: 10px;
            // border-top-left-radius: 5px !important;
            // border-bottom-left-radius: 5px !important;
            // border-right: none;

            &+.parent-toggle {
                background-color:$gray-darken;
            }
        }
        &.active-item{
            cursor: default;
        }
    }

    .side-menu-icon {
        // width: $side-menu-icon-width !important;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
    }
    .side-menu-text {
        vertical-align: middle;
        font-size: 18px;
        margin-left: 5px;

        &.side-menu-text--sm {
            font-size: 16px;
            margin-left: 0px;
        }
    }

    .side-menu-text--inventory-management {
        line-height: 1rem;
        width:$side-menu-text-width - 10px;
    }

    .side-menu-toggler {
        width: $side-menu-toggler-width;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
    }
    .nav-link {
        border-radius: 0px !important;
    }

    .icon {
        width: 40px;
        height: 40px;
        margin: auto;
        background-size: 100%;
    }
    
    .icon-blink{
        @extend .import-export-red;
        color: $soft-red !important;
        animation: flash ease-in 2s infinite;
        -webkit-animation: flash ease-in s infinite;
            @-webkit-keyframes flash {
                0% { opacity: 1; } 
                50% { opacity: .1; } 
                100% { opacity: 1; }
                }
            @keyframes flash {
                0% { opacity: 1; } 
                50% { opacity: .1; } 
                100% { opacity: 1; }
    }
    }

    .menu-section {
        border-bottom: thin solid $theme-color-border-bottom;

        .side-menu-item-container:not(:first-child){
            padding: 0;
        }

    }
}


#app-shell-main-content {
    // padding-left: $app-shell-main-content-padding;
    // padding-right: $app-shell-main-content-padding;
    margin-top: $sticky-header-height;
    height: calc(100vh - 46px - 30px);  // 100vh - headerHeight - footerHeight


    &.side-menu-open {
        @include media-breakpoint-up(md) {
            // Push the content across as much as the opened menu, +3rem for bootstrap paddings
            margin-left: $side-menu-open-total-width;
            // width:calc(100vw - #{$side-menu-open-total-width});
            // left: $side-menu-open-total-width;
        }
    }

    &.side-menu-closed {
        @include media-breakpoint-up(md) {
            // Push the content across as much as the closed menu, +3rem for bootstrap paddings
            // margin-left: 69px; //domi here
            margin-left: 80px;
            // width:calc(100vw - #{$side-menu-closed-total-width});
            // left: $side-menu-closed-total-width;
        }
    }
}

#noty_topRight_layout_container {
    top: 50px !important;
}
