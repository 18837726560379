.payment-to-account {
    width: 80%;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    /* Chrome, safari, Edge, Opera*/
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        display: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
  }
}