@mixin readOnlyFields($display) {
    .form-control-static, .edit-mode-off {
        display: $display;
    }
}

@mixin editableFields($display) {
    .form-control, .editModeButton, .edit-mode-on, .input-group-addon, label.custom-radio {
        display: $display !important;
    }
}

.page-editable {
    @include readOnlyFields(none);

    .element-readonly {
        @include readOnlyFields(inherit);
        @include editableFields(none);
    }
}

.page-readonly {
    @include editableFields(none);
}

.form-p-custom {
border: 1px solid #ccc;
background-color:lightyellow;
padding: 5px 5px 5px 15px !important;
border-radius: 0.28rem;
height: 36px;
}

.credit-custom-input{
    border: 1px solid #ccc;
    background-color: lightyellow;
    padding: 5px 17px 5px 6px !important;
    border-radius: 0.28rem;
    height: 35px;
}

.read-only-custom {
    background-color: $greyed-background-color;
    border: 1px solid $greyed-border-color; 
    border-radius: 0.25rem;
    color: darkgrey;
    font-style: normal;
    font-weight: 400;
    min-height: 27px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
}
