@import "./dashboard";
@import "./accounting/app-questions/app-questions";
@import "./payments/payments";

.helpful-grid {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @extend .theme-border-color;

    .col-label {
        @extend .col-md-2;
        @extend .col-lg-2;
    }

    .col-input {
        @extend .col-md-7;
        @extend .col-lg-6;
    }

    .col-help {
        @extend .col-md-3;
        @extend .col-lg-4;

        font-size: 0.9em;
    }

    .helpful-row {
        @extend .row;
        border-top-style: solid;
        @extend .theme-border-color;
        border-top-width: 1px;
        padding: 1em 0;
        margin-bottom: -1px;
        &:first-child{
            border-top-width: 0px;
        }
    }
}