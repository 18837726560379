modal#operationalToAcc {
    operational-to-account-wizard {
        display: block;
        min-width: 60rem;
        max-height: 40rem;
        overflow: auto;
    }

    .sub-header {
        font-weight: 600;
        margin-bottom: $sub-header-margin-bottom;
        padding-bottom: $sub-header-padding-bottom;
        border-bottom: $border-width solid $greyed-border-color;

        &.chevron:after {
            content: "\f078"; /* Unicode character for "minus" sign (-) */
            font-family: "FontAwesome";
            // font-size: 13px;
            color: #777;
            float: right;
            margin-left: 0.5rem;
            cursor: pointer;
        }
        
        &.collapsed:after {
            font-family: "FontAwesome";
            content: '\f077'; /* Unicode character for "plus" sign (+) */
            cursor: pointer;
        }
    }

    .class-frame {
        @extend .theme-frame;
        min-height: 164px;
        max-height: 164px;
        min-width: 300px;
        max-width: 300px;
    }

    .helper-text{
        font-size: small;
        color: $gray-dif;
        font-style: italic;
    }
    

    .customer-card-details {
        background: $white;
        box-shadow: 0 1px 8px -1px rgba(0,0,0,0.95);
        border-radius: 0.5rem;
        padding: 1rem;
    }

    input:read-only,textarea {
        background-color: #f4f4f4 !important;
        color: black;
        font-weight: 600;
        max-height: 23px;
        text-overflow: ellipsis;
      }

}
