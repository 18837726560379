$app-image-directory: "../../images/svg/";
$logo-main: ( 
    sheet-url: url($app-image-directory + "largeRocket.svg"),
    background-pos: 0px 0px,
    width: 200px,
    height: 200px,
    sheet-url-x2: url($app-image-directory + "largeRocket.svg"),
    image-width-x2: 200px,
    image-height-x2: 60px,
    background-pos-x2: 0px 0px
);