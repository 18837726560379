.gallery-item {
    //max-width: 200px;
    position: relative;
    margin-bottom:0.5rem;

    &:only-child{
        margin-bottom:0px;
    }

    .delete-gallery-item {
        position: absolute;
        right: 15px;
        top: 0;
    }
}
