.stocktake-card {
    > div {
        max-width: 20rem;
    }
}

.fa-exclamation-triangle:not(disabled):hover + ul {
    background: #555;
    color: #fff;
    display: block !important;
    font-size: 14px;
    list-style: none;
    position: relative;
    right: 3rem;
    top: 0;

    &:after {
        left: 100%;
        top: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-left-color: #555;;
        border-width: 10px;
    }

    li {
        line-height: 0.75rem;
    }
    
}

.item-row {
    div.input-group {
        .input-group-addon {
            background: #fff;
            border: none;
            text-align: center;
        }
    }
}

.save:hover:enabled {
    filter: drop-shadow(0px 0px 5px rgba(43, 202, 250, 0.938));
}

.trash:hover:enabled {
    filter: drop-shadow(0px 0px 5px rgba(250, 195, 43, 0.938));
}

.attention:hover:enabled {
    filter: drop-shadow(0px 0px 5px rgba(250, 43, 43, 0.938));
    z-index: 3;
}
