
.synk-status-panel-container{
    flex-wrap: wrap;
    margin: 0 0 3em 0;
    padding: 2rem;
    background: $active-row-background-color;
    border: $thin-border-width solid $theme-color-text;
    border-radius: .25rem;
  
    .panel-separator{
    border-bottom: $thin-border-width solid $panel-separator;
    }

    .cell {
    font-size: 14px;
    }

    .no-icon{
    color: $no-icon;
    opacity: 0.5;
    }
}