

/*
structure:

.content-header
.scrollbox-body
    .scrollbox-wrapper
        .scrollbox-wrapper-child * n
            .scrollbox
                *
                    .scrollbox-sticky-header
                    .scrollbox-sticky-footer
*/

// descendents of .scrollbox-viewport, required to be full height and width of the viewport.
.scrollbox-wrapper {
    height:100% !important;
    display: block;
    .scrollbox-wrapper-child {
        height:100%;
        flex-wrap: nowrap;

    }
}
// requried to calculate the height of scrollbox-body
.content-header {
    height: $scrollbox-header-height;
    width:100%;
    margin-bottom:0px !important;
    margin-top:0px !important;
    padding-left:10px;
    display: block;
}
//
.scrollbox-body {
    height:  calc( 100vh - #{$scrollbox-header-height} - #{$sticky-footer-height} - #{$sticky-header-height});
    width:100%;
    display: block;
}
// this element actually scrolls.
.scrollbox{
    height:100%;
    @extend %scrollbox-scroll;
}

%scrollbox-scroll {
    overflow-y:scroll;
    overflow-x:hidden;
}
%scrollbox-no-scroll {
    overflow-y:hidden !important;
    overflow-x:hidden !important;
}
%scrollbox-sticky{
    position: -webkit-sticky !important;
    position: -moz-sticky !important;
    position: -ms-sticky !important;
    position: -o-sticky !important;
    position: sticky !important;
    z-index:$scrollbox-sticky-z-index;
    display:block;
    // overflow: visible;
    overflow:hidden;
}
.scrollbox-dashboard {
    @extend %scrollbox-sticky;
    top: 43px;
    background-color:#F9F9F9;
}
.scrollbox-sticky-header,
.scrollbox-top {
    @extend %scrollbox-sticky;
    overflow: visible;
    top: 0px;
}
.scrollbox-sticky-footer {
    bottom:$scrollbox-footer-height;
    overflow: visible;
    position: fixed;
    z-index:$scrollbox-sticky-z-index;
}

.scrollbox-fixed-footer {
    display:block;
    width:100%;
    .scrollbox-fixed-footer-inner{
        position: fixed;
        width:100%;
        bottom:$sticky-footer-height;
    }
}

.scrollbox-top-btn{
    @extend .btn;
    @extend .font-size-large;
    @extend .theme-background-color;
    @extend .theme-border-color;
    @extend .theme-text-color;
    @extend .px-2;
    @extend .py-1;
    position: absolute;
    right: 0px;
    top:0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    z-index:$scrollbox-sticky-z-index;
    display: none;
    &:focus{
        box-shadow:none;
    }
}

.scrollbox-sticky-header.position-relative {
    position: relative !important;
}
