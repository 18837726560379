$header-text-color: white;

#sticky-header-container {
    position: fixed;
    top: 0;
    right: 0;
    padding-left: 80px;
    height: $sticky-header-height;
    line-height: 3rem; /* $sticky-header-height; Vertically center the text there */
    background-color: $header-background-color; // Set by theme for now
    border-bottom: 1px darken($header-background-color, $border-darken-amount) solid;
    color: $header-text-color;
    width: 100%;
    z-index: $header-footer-z-index;

    &.collapsed {
    padding-left: 171px;
    }

    nav,app-title{
        > span{
            line-height: $sticky-header-height; /* Vertically center the text there */
        }
    }

    > div{
        height: $sticky-header-height;
    }
    .parent-ring-btn{
        padding:0px !important;
        margin:0px !important;
        border:0px !important;
        &.side-menu-toggle{
            width:$side-menu-closed-total-width;
        }
    }

    /* Reset line height for multi-line items */
    .btn-group {
        line-height: normal;
    }

    button {
        color: $header-text-color;
        background-image: none;
        background-color: transparent;
        border-color: transparent;
    }

    .migration {
        background-color: $orange;
        border-radius: .25rem;
        color: #FFF;
        padding: .25rem;
            &:hover {
                cursor: pointer !important;
            }
    }

}