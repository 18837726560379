.market-place-color {
    @include market-place-icon($theme-second-color);
}

.soh-equal-color {
    @include soh-equal-icon($white);
}

.stock-adjustment-black {
    @include stock-adjustment-icon($theme-color-wh-crail);
}

.stock-on-hand-white {
    @include stock-on-hand-icon($white);
}

.stock-on-hand-color {
    @include stock-on-hand-icon($theme-color-wh-saltbox);
}

.stock-in-transit-white {
    @include stock-in-transit-icon($white);
}

.stock-in-transit-color {
    @include stock-in-transit-icon($theme-color-wh-sandrift);
}

.stock-on-order-white {
    @include stock-on-order-icon($white);
}

.stock-on-order-color {
    @include stock-on-order-icon($theme-color-wh-calypso);
}

.stock-available-white{
    @include stock-available-icon($white);
}

.stock-available-color {
    @include stock-available-icon($theme-color-wh-green);
}

.chevron-white {
    @include chevron($white);
}

.chevron-color {
    @include chevron($theme-second-color);
}

.plus-white {
    @include plus($white);
}

.stocktake-deleteLine-color{
    @include stocktake-deleteLine-icon($orange);
}

.stocktake-saveLine-color {
    @include stocktake-saveLine-icon($theme-second-color);
}

.stocktake-status-black {
    @include stocktake-status-icon($theme-color,$white);
}

.stocktake-created-black{
    @include stocktake-created-icon($theme-color,$white);
}

.stocktake-type-black {
    @include stocktake-type-icon($theme-color,$white);
}

.warehouse-name-white {
    @include warehouse-name-icon($white);
}

.warehouse-name-black {
    @include warehouse-name-icon($black);
}

.stocktake-history-color{
    @include stocktake-history-icon($theme-color-wh-purple);
}

.warehouse-ranging-color{
    @include warehouse-ranging-icon($theme-color-wh-darkblue);
}

.warehouse-deactivate-color{
    @include warehouse-deactivate-icon($theme-color-wh-red);
}

.openingBalance-color {
    @include openingBalance-icon($theme-color-wh-green);
}

.stocktake-wh-orange {
    @include stocktake-icon($theme-color-wh-orange);
}

.stocktake-white {
    @include stocktake-icon($white);
}

.stocktake-color {
    @include stocktake-icon($theme-second-color);
}

.stocktake-black {
    @include stocktake-icon($theme-color);
}

.warehouses-white {
    @include warehouses-icon($white, $theme-second-color);
}

.warehouses-color {
    @include warehouses-icon($theme-second-color, $white);
}

.warehouses-black {
    @include warehouses-icon($theme-color, $white);
}

.inventory-management-gray{
    @include inventory-management-icon($white, $gray-dif);
}

.inventory-management-white {
    @include inventory-management-icon($white, $white);
}

.inventory-management-color {
    @include inventory-management-icon($theme-second-color, $white);
}

.inventory-management-black {
    @include inventory-management-icon($theme-color, $white);
}

.mobile-warehouse-color {
    @include mobile-warehouse-icon($theme-second-color);
}

.mobile-warehouse-white {
    @include mobile-warehouse-icon($white);
}

.physical-warehouse-color {
    @include warehouse-name-icon($theme-second-color);
}

.service-jobs-white {
    @include service-jobs-icon($white, $theme-second-color);
}

.service-jobs-color {
    @include service-jobs-icon($theme-second-color, $white);
}

.service-jobs-black {
    @include service-jobs-icon($theme-color, $white);
}

.sales-menu-white {
    @include sales-menu-icon($white, $theme-second-color);
}

.sales-menu-color {
    @include sales-menu-icon($theme-second-color, $white);
}

.sales-menu-black {
    @include sales-menu-icon($theme-color, $white);
}

.batch-billing-white {
    @include batch-billing-icon($white, $theme-second-color);
}

.batch-billing-color {
    @include batch-billing-icon($theme-second-color, $white);
}

.batch-billing-black {
    @include batch-billing-icon($theme-color, $white);
}

.bell-white {
    @include bell($white);
}

.claimd-white {
    @include claimd-icon($white);
}

.claimd-color {
    @include claimd-icon($theme-second-color);
}

.claimd-black {
    @include claimd-icon($theme-color);
}

.connect-dark {
    @include connect-icon($theme-color, lighten($theme-color,20%));
}

.connect-color {
    @include connect-icon($theme-second-color, rgb(147, 200, 220));
}

.connect-light {
    @include connect-icon($white, $white);
}

.menu-bars-white {
    @include menuBars(rgb(255, 255, 255));
}

.user-white {
    @include user(rgb(255, 255, 255));
}

.user-color {
    @include user($theme-second-color);
}

.user-black {
    @include user($theme-color);
}

.supplier-white {
    @extend .status-icon;
    @include supplier-icon($white);
}

.supplier-color {
    @extend .status-icon;
    @include supplier-icon($theme-second-color);
}

.supplier-black {
    @include supplier-icon($theme-color);
}

.settings-white {
    @include settings-icon($component-background-color);
}

.support-color {
    @include support-icon($theme-second-color);
}

.reports-color {
    @include reports-icon($theme-second-color);
}

.reports-white {
    @include reports-icon($white);
}

.support-black {
    @include support-icon($theme-color);
}

.sold-white {
    @include sold-icon($white);
}

.sold-color {
    @include sold-icon($theme-second-color);
}

.sold-black {
    @include sold-icon($theme-color);
}

.inventory-white {
    @extend .status-icon;
    @include inventory-icon($white, $white, $white);
}

.inventory-color {
    @extend .status-icon;
    @include inventory-icon(lighten($theme-second-color, 20%), $theme-second-color, darken($theme-second-color, 10%));
}

.inventory-black {
    @include inventory-icon(lighten($theme-color, 20%), $theme-color, darken($theme-color, 10%));
}

// .home-black {
//     @include home-icon("#333");
// }

// .home-full-black {
//     @include home-icon-full("#333");
// }

.account-customer-white {
    @extend .status-icon;
    @include account-customer-icon($black, $white, $white);
}

.account-customer-color {
    @extend .status-icon;
    @include account-customer-icon(lighten($theme-second-color, 20%), $theme-second-color, darken($theme-second-color, 20%));
}

.account-customer-black {
    @extend .status-icon;
    @include account-customer-icon(lighten($theme-color, 20%), $theme-color, darken($theme-color, 20%));
  
}

.customer-white {
    @extend .status-icon;
    @include customer-icon($white, $white, $white);
}

.customer-color {
    @extend .status-icon;
    @include customer-icon(lighten($theme-second-color, 20%), $theme-second-color, darken($theme-second-color, 20%));
}

.accounts-white {
    @extend .status-icon;
    @include accounts-icon($white);
}

.accounts-color {
    @extend .status-icon;
    @include accounts-icon($theme-second-color);
}

.customer-black {
    @include customer-icon(lighten($theme-color, 20%), $theme-color, darken($theme-color, 20%));
}

.claimd-sent {
    @extend .status-icon;
    @include claim_status_sent_icon($theme-second-color);
}

.categories-white{
    @include categories-icon($white);
}

.categories-color{
    @extend .status-icon;
    @include categories-icon($theme-second-color);
}


.categories-black{
    @include categories-icon($theme-color);
}


.classes-color{
    @extend .status-icon;
    @include classes-icon($theme-second-color);
}

.classes-white{
    @include classes-icon($white);
}

.classes-black{
    @include classes-icon($black);
}

.brands-white{
    @extend .status-icon;
    @include brands-icon($white);
}

.brands-color{
    @extend .status-icon;
    @include brands-icon($theme-second-color);
}

.brands-black{
    @include brands-icon($theme-color);
}

.data-manager-color{
    @include data-manager-icon($theme-second-color);
}

.data-manager-black{
    @include data-manager-icon($theme-color);
}

.data-manager-white{
    @include data-manager-icon($white);
}

.import-export-color{
    @include import-export-icon($theme-second-color);
}

.import-export-black{
    @include import-export-icon($theme-color);
}

.import-export-red{
    @include import-export-icon($soft-red);
}

.payments-color{
    @extend .status-icon;
    @include payments($theme-third-color);
}

.payments-white{
    @extend .status-icon;
    @include payments($white);
}

.invoices-color{
    @extend .status-icon;
    @include invoices($theme-third-color);
}

.invoices-white{
    @extend .status-icon;
    @include invoices($white);
}

.credit-notes-color{
    @extend .status-icon;
    @include credit-notes($theme-third-color);
}

.credit-notes-white{
    @extend .status-icon;
    @include credit-notes($white);
}

.debit-notes-color{
    @extend .status-icon;
    @include debit-notes($theme-third-color);
}

.debit-notes-white{
    @extend .status-icon;
    @include debit-notes($white);
}

.purchase-invoice-color{
    @include purchase-invoice($theme-third-color);
}

.purchase-invoice-white{
    @include purchase-invoice($white);
}

.puzzle-white{
    @include puzzle($white);
}

.sales-order-color{
    @include sales-order($theme-third-color);
}

.sales-order-white{
    @include sales-order($white);
}

.tiny-arrow{
    @include tiny-arrow($theme-third-color);
}

.status-error-color{
    @extend .status-icon;
    @include error-icon($theme-third-color);
}

.status-ready-color{
    @extend .status-icon;
    @include synk-ready-icon($theme-third-color);
}
.status-ready-color-icon{
    @include synk-ready-icon($theme-small-icon-color);
}

// .status-ready-light{
//     @include synk-ready-icon($white);
// }

.status-ready-light{
    @extend .status-icon;
    @include claim_status_new_icon($white);
}

.status-ready-white{
    @extend .status-icon;
    @include synk-ready-icon($white);
}

.status-ignored-color{
    @extend .status-icon;
    @include pause-icon($theme-third-color);
}

.status-ignored-color-icon{
    @include pause-icon($theme-small-icon-color);
}

.status-ignored-light{
    @extend .status-icon;
    @include pause-icon($white);
}

.new_box_white {
    @extend .status-icon;
    @include claim_status_processing_icon($white);
}

.status-invalid-color{
    @include caution-icon($theme-third-color);
}

.status-synked-color{
    @extend .status-icon;
    @include synk-ok-icon($theme-third-color);
}

.status-synked-light{
    @extend .status-icon;
    @include synk-ok-icon($white);
}

.status-imported-color{
    @extend .status-icon;
    @include imported-icon($theme-third-color);
}

.status-imported-light{
    @extend .status-icon;
    @include imported-icon($white);
}