amended-property {

    p {
        margin-bottom: 0;

        &.cross-out {
            text-decoration: line-through;
            color: orangered;
        }
    }

    .form-group.amended {
        background-color: rgba(78, 73, 73, 0.26);
        border-radius: 4px;
        box-shadow: 0 0 1px #000;
        padding: 5px;
    }
}