// Bootstrap overrides. Instead of editing the file in the node_modules like
// they suggest, alter this instead and it will be copied into the modules
// for when it is compiled. node_modules isn't under source control and this
// also protects against upgrades.
//
// Variables that can be overridden are in the bootstrap node_module file
// `_variables.scss`. Just remove the !default at the end

.custom-control-indicator {
    // Not a fan of the bootstrap way of making the whole background grey out with the custom checkbox.
    // I'm not sure why they styled it like that, it makes it look like a disabled control (as inputs
    // are normally greyed out when disabled). Instead make the background white like it normally is
    // in the browser.
    border: 1px solid rgba(0,0,0,0.15);
    background-color: white;
}

.custom-control-ourCustom {
    // This is a Bootstrap class used in checkboxes and radio buttons in ASWeb.
    // Reason for override: we dont want the text beside the checkbox and radio button getting selected
    // when they are clicked multiple times. 
    @extend .custom-control;
    user-select:none;
}

.form-control {
    font-size: 0.875rem !important;
}

h2, h3, h4, h5 {
    font-weight: 400 !important;
}