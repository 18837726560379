.has-danger {
    .form-control {
        @extend .is-invalid;
    }    
    .form-control-label {
        color: $red !important;
    }
}

.form-control-feedback {
    text-align: center;
    font-size: 0.9em;
    color: $red;
    font-weight: 600;
}
