.multiple-errors li {
    margin-left: 1rem;
}

.single-error {
    list-style-type: none;
}

.validation-message {
    color: $red;
}