.standard-public-content-width {
    max-width: 35rem;
}

// Browser detection
#jr_overlay {
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	z-index: 2147483646;
	position: absolute;
}

#jr_wrap {
	position: absolute;
	width: 100%;
	z-index: 2147483647;
	padding: 0;
	margin: 0;
}

#jr_inner {
	font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Verdana, sans-serif;
	font-size: 12px;
	background: #FFF;
	border: 1px solid #CCC;
	color: #4F4F4F;
	margin: 0 auto;
	height: auto;
	padding: 20px;
	position: relative;
	box-sizing: content-box;
}

#jr_header {
	display: block;
	color: #333;
	padding: 5px;
	padding-bottom: 0;
	margin: 0;
	font-family: Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-size: 1.3em;
	margin-bottom: 0.5em;
}

#jr_inner p {
	padding: 5px;
	margin: 0;
}

#jr_inner ul {
	list-style-image: none;
	list-style-position: outside;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

#jr_inner ul li {
	cursor: pointer;
	float: left;
	width: 120px;
	height: 122px;
	margin: 0 10px 10px 10px;
	padding: 0;
	text-align: center;
}

#jr_inner li a {
	color: #333;
	font-size: 0.8em;
	text-decoration: none;
	padding: 0;
	margin: 0;
}

#jr_inner li a:hover {
	text-decoration: underline;
}

#jr_inner .jr_icon {
	width: 100px;
	height: 100px;
	margin: 1px auto;
	padding: 0;
	background: transparent no-repeat scroll left top;
	cursor: pointer;
}

#jr_close {
	clear: both;
	padding: 0;
	margin: 0;
}

#jr_close a {
	color: #000;
	display: block;
	width: auto;
	margin: 0;
	padding: 0;
	text-decoration: underline;
}

#jr_close p {
	padding: 10px 0 0 0;
	margin: 0;
}
