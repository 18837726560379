//Changes the color and the hovering properties of the navbar
@mixin navbar-variant($color, $font-color: rgba(255, 255, 255, 0.8), $hover-color: rgb(246,246,246), $hover-bg: rgba(0, 0, 0, 0.1)) {
  background-color: $color;
  //Navbar links
  .nav > li > a {
    color: $font-color;
  }

  .nav > li > a:hover,
  .nav > li > a:active,
  .nav > li > a:focus,
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus,
  .nav > .active > a {
    background: $hover-bg;
    color: $hover-color;
  }

  //Add color to the sidebar toggle button
  .sidebar-toggle {
    color: $font-color;
    &:hover {
      color: $hover-color;
      background: $hover-bg;
    }
  }
}

//Logo color variation
@mixin logo-variant($bg-color, $color: white, $border-bottom-color: transparent, $border-bottom-width: 0) {
  background-color: $bg-color;
  color: $color;
  border-bottom: $border-bottom-width solid $border-bottom-color;

  &:hover {
    background-color: darken($bg-color, 1%);
  }
}

//Box solid color variantion creator
@mixin box-solid-variant($color, $text-color: white) {
  border: 1px solid $color;
  > .box-header {
    color: $text-color;
    background: $color;
    background-color: $color;
    a,
    .btn {
      color: $text-color;
    }
  }
}

//Direct Chat Variant
@mixin direct-chat-variant($bg-color, $color: white) {
  .right > .direct-chat-text {
    background: $bg-color;
    border-color: $bg-color;
    color: $color;
    &:after,
    &:before {
      border-left-color: $bg-color;
    }
  }
}

//Different radius each side
@mixin border-radius($top-left, $top-right, $bottom-left, $bottom-right)
{
  border-top-left-radius: $top-left;
  border-top-right-radius: $top-right;
  border-bottom-right-radius: $bottom-right;
  border-bottom-left-radius: $bottom-left;
}

//Gradient background
@mixin gradient($color: rgb(245,245,245), $start: rgb(238,238,238), $stop: rgb(255,255,255), $important:"") {
  background: $color;
  background: -webkit-gradient(linear,left bottom,left top,color-stop(0, $start),color-stop(1, $stop)) unquote($important);
  background: -ms-linear-gradient(bottom,$start,$stop) unquote($important);
  background: -moz-linear-gradient(center bottom, $start 0%, $stop 100%) unquote($important);
  background: -o-linear-gradient($stop, $start) unquote($important);
  //filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",$stop,$start)) unquote($important);
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    // IE8 filter
    $opacity-ie: ($opacity * 100);
    filter: alpha(opacity=$opacity-ie);
}

//transitions
@mixin transition-one($prop,$time:0.5s){
    transition: $prop $time;
    -moz-transition: $prop $time;
    -ms-transition: $prop $time;
    -webkit-transition: $prop $time;    
}
/*
* first argument is the time step for all properties.
* all following arguments are the property names:
* @include transition-multi(0.5s,background,border);
*/
@mixin transition-multi($args...){
    $transition-line:"";
    $time: nth($args,1);
    @for $i from 2 through length($args){
        $item: nth($args,$i);
        $transition-line: $transition-line + $item + " " + $time;
        @if $i < length($args) {
            $transition-line: $transition-line + ", ";
        }
    }
    transition: unquote($transition-line);
    -moz-transition: unquote($transition-line);
    -ms-transition: unquote($transition-line);
    -webkit-transition: unquote($transition-line); 
}

@mixin transition-all($time:0.5s){
    @include transition-one(all,$time);
}