$blue: #ebf5f9;
$gray: #f9f9f9;
$darkblue: #4ea5c5;
$green: #72bf1f;
$red: #c85555;
$orange: #d4a04a;
$border: #d1d1d1;

.channel-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    channel, .channel {
        flex: 1 1 6rem;
        border-right: 1px solid #F1F1F1;

        .channel-header{
            // border: 1px solid red;
            min-height: 12rem;

            span.btn, small {
                cursor: pointer;
            }

            span.btn:hover + small, small:hover {
                text-decoration: underline;
            }

            .channel-header__info{
                display: flex;
                justify-content: center;

                .channel-logo {
                    border: 1px solid $border;
                    border-radius: 4px;
                    height: 4.125rem;
                    text-align: center;
                    width: 4.125rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        border-style: none;
                        max-height: 4rem;
                        max-width: 4rem;
                        padding: 0.125rem;
                        vertical-align: middle;
                    }

                    &.channel-logo--error {
                        border-color: $red;
                        border-width: 2px;
                    }
                }

                .channel-header-orgname {
                    max-width: 10rem;
                }

                small.connect {
                    background-color: $orange;
                    border-radius: .25rem;
                    color: #FFF;
                    padding: .25rem;
                }
            }
        }

        .channel-add-new {
            border: 1px dashed lightgray;
            border-radius: 5px;
            color: lightgray;
            text-align: center;
            text-transform: capitalize;

            .channel-add-new--icon {
                border-radius: 50%;
                border: 1px dashed;
                height: 4rem;
                width: 4rem;

                &:hover {
                    transform: scale(1.125);
                    cursor: pointer;
                }
            }
        }
    }

    .channel {
        flex-grow: 0.5;
    }

    .add-connection {
        flex-grow: 0.125;
        text-align: center;

        span.icon {
            height: 5rem;
            width: 5rem;
            margin: 0 auto;
        }

        button {
            font-family: "Saira Extra Condensed",sans-serif;

            i.icon {
                height: 1rem;
                width: 1rem;
                display: inline-block;
                position: relative;
                top: 2px;
            }

            small {
                background-color: $orange;
                border: 1px solid $white;
                border-radius: 5px;
                font-size: 70%;
                // line-height: 1.25;
                padding: 0px 5px;
                position: absolute;
                right: -5px;
                top: -15px;
            }
        }

    }
}

.dattype-info {
    border: 1px solid $border;
    margin-bottom: 1rem;
    min-height: 6.5rem;
    position: relative;

    i.icon {
        height: 2.3rem;
        position: relative;
        top: 8px;
        width: 2.3rem;
    }

    i.fa.faa-bounce {
        color: $darkblue;
    }
}

datatype-status-summary {
    &:nth-child(odd){
        .dattype-info {
            background: $gray;
        }
    }

    &:nth-child(even){
        .dattype-info {
            background: $blue;
        }
    }
}

@keyframes shake {
	0%,10%,20%,50%,70%,100%{transform:translateX(0)}
	40%{transform:translateX(-10px)}
	60%{transform:translateX(-10px)}
    80%{transform:translateX(-10px)}
}

@keyframes bounce {
	0%,10%,20%,50%,80%,100%{transform:translateY(0)}
	40%{transform:translateY(-10px)}
	60%{transform:translateY(-10px)}
}

.faa-shake.animated {
    animation: shake 1s ease infinite;
}

.faa-bounce.animated,
.faa-bounce.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-bounce {
	animation: bounce 1s ease infinite;
}

.dattype-synkd-status {
    display: flex;

    .connected-count {
        flex: 1 1 60%;
        position: relative;

        .connected-count__title{
            display: block;
        }

        .connected-count__digits{
            text-align: center;
        }

        .connected-count__actions{
            position: relative;
            top: -0.25rem;
        }

        .loading-bars {
            position: absolute;
            right: 10px;
            top: 5px;
        }

        i.fa.faa-bounce {
            position: absolute;
            right: 45px;
            top: 15px;
        }

        .ready-count {
            background: #4ea5c5;
            border-radius: 5px;
            color: #FFF;
            // cursor: pointer;
            padding: 1px 10px 8px;
            position: relative;
            top: -5px;
            width: 75px;
            display: inline-block;

            &:after {
                content: "Ready To Sync";
                bottom: 0;
                font-size: 9px;
                left: 11px;
                position: absolute;
            }
        }

    }

    .disconnected-count{
        border-left: 1px solid $border;
        flex: 1 1 40%;

        .disconnected-count__digits {
            align-self: center;
            font-size: 20px;
            position: absolute;
            right: 5px;
        }

        .errored-count{
            border-bottom: 1px solid $border;
            display: flex;
            height: 50%;
            padding-left: 5px;
            position: relative;

            &.errored-count-highlight {
                background: #FF6767;
                color: #fff;

                &:hover {
                    color: darken($red, 10%);
                    // cursor: pointer;
                }

                .counter--large {
                    &:hover {
                        color: darken($red, 10%);
                        cursor: default;
                    }
                }
            }
        }

        .ignored-count {
            display: flex;
            height: 50%;
            padding-left: 5px;
            position: relative;
            // &:hover {
            //     cursor: pointer;
            // }
        }
    }
}

.counter--large {
    font-size: 28px;
    font-weight: 600;

    &:hover {
        color: $darkblue;
        cursor: pointer;
    }
}

.btn {

    &.btn--blue {
        background: $darkblue;
        color: #FFF !important;
    }

    &.btn-square--sm {
        padding: 0;
        width: 25px;
        height: 25px;
    }
}

.connection-span {
    &:hover {
        cursor: unset !important;
        text-decoration: none !important;
    }
}

.connection-icon {
    border-radius: 50%;
    color: #FFF;
    height: 20px;
    position: relative;
    width: 20px;

    span{
        font-weight: 600;
        position: relative;

    }



    &.connection-icon--ok{
        background: $green;

        span {
            font-size: 10px;
            top: -5px;
            left: 3px;

        }
    }
    &.connection-icon--error{
        background: $red;

        span {
            font-size: 18px;
            left: 7px;
            top: -4px;

        }
    }
}

.border-bottom {
    border-bottom: 1px solid;
}

#channelSetup {

    div.tab-pane.active {
        background-color: #FFF !important;
        color: #000;
    }

    .tab-content {
        min-height: 30rem;
        overflow-y: auto;
        width: 40rem;
        padding-top: 0.5rem;
        padding-left: 3px;
        overflow-x: hidden;
    }

    div .form-control-static {
        display: none;
    }

    .channel-setup-default-customer-class-alert-icon {
        color: $orange;
        display: inline-block;
        font-size: $default-icon-size;
        text-align: initial;
    }
}

#scheduledTasksSetup {

    .modal-content {
        width: 32rem;
    }
}

data-flow-item {

    &:nth-child(odd){

        .data-flow-item {
            background-color: #F4F4F4;
        }
    }
}

.data-flow-item {

    display: flex;
    justify-content: center;
    min-height: 4.5rem;
    align-items: center;

    span {
        border: 1px solid;
        border-radius: 15px;
        display: block;
        height: 30px;
        margin: 0 auto;
        position: relative;
        width: 30px;

        i {
            left: 1px;
            position: relative;
            top: 1px;
        }
    }

    span.icon {
        border: 0;
        height: 2.5rem;
        width: 2.5rem;
    }

    .data-flow-item__source, .data-flow-item__target {

        img{
            max-height: 4rem;
            width: 4rem;
        }

        .icon-rocket{
            @include home-icon("white");
        }
    }

    .data-flow-item__type {
        color: #000;
        min-width: 8rem;
    }
}

.arrows {
    margin: 0 1rem;
}

.arrow {
    border-bottom: 4px solid #BBB;
    cursor: pointer;
    position: relative;
    width: 120px;
    z-index: 1;

    &:first-child {
        margin-bottom: 1rem;
    }

    &.active {
        border-color: #4EA5C5;

        .arrow__arrowhead {
            border-color: #4EA5C5;
        }
    }

    .arrow__arrowhead {
        border-bottom-right-radius: 3px;
        border-right: 3px solid #BBB;
        border-top: 3px solid #BBB;
        cursor: pointer;
        height: 10px;
        position: absolute;
        width: 10px;

        &.arrow__arrowhead--left {
            left: -2px;
            top: -3px;
            transform: rotate(-135deg);
        }

        &.arrow__arrowhead--right {
            right: -2px;
            top: -3px;
            transform: rotate(45deg);
        }
    }
}

adaptor-instruction label {
    color: #000;
}

i.percentage {
    bottom: 0px;
    color: #4ea5c5;
    font-size: 1rem;
    font-style: normal;
    position: absolute;
    left: 0;

    &.write{
        left: 3.5rem;
    }
}

.msg-details {
    background: white;
    border: 1px solid #72bf1f;
    border-radius: 4px;
    bottom: 4rem;
    height: 1.5rem;
    opacity: 0;
    position: absolute;
    transition: bottom 2s, opacity 3s;
    width: 100%;
    z-index: 999;
    font-size: 12px;
    padding: 2px;

    &.msg-details-hidden {
        bottom: 5.5rem;
        opacity: 1;
    }
}

.synk-read-popup {
    min-width: 700px;
}


#svg2 {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 50px;
    height: 40px;
    margin: -20px 0 0 -25px
}
#svg2 rect {
    fill: #4ea5c5;
    fill-opacity:1;
    fill-rule:evenodd;
    stroke:#748591;
    stroke-width:0.1px;
    stroke-linecap:butt;
    stroke-linejoin:miter;
    stroke-opacity:1;
    opacity: 0;
    animation-name: invis;
    animation-duration: 1s;
    animation-timing-function: steps(8);
    animation-iteration-count: infinite;
}
#svg2 rect:nth-child(1) {
    animation-delay: 0;
}
#svg2 rect:nth-child(2) {
    animation-delay: 0.166667s;
}
#svg2 rect:nth-child(3) {
    animation-delay: 0.333333s;
}
@keyframes invis {
    25% {
        opacity:1;
    }
    50% {
        opacity: 0;
    }
}

.config-group-setup {
    max-height: 45rem;
    overflow-y: auto;
}