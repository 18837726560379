// .ag{
//     height: 95px;
//     max-width: 100%;
// }

.Synkd .ag-theme-bootstrap .ag-row {
    border-bottom: 1px solid #e9ecef;
}

.Synkd .ag-theme-bootstrap .ag-cell {
    line-height: 35px;
}


.Synkd .ag-theme-bootstrap .ag-header-cell-label {
    justify-content: center;
}

.Synkd .ag-theme-bootstrap .ag-header-cell-label .ag-header-cell-text {
    display: flex;
    align-items: center;
}