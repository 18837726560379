
#adaptors-dashboard {
  // @font-face {
  //   font-family: 'Saira Extra Condensed', sans-serif;
  //   src: url("../fonts/saira-extra-condensed-latin-500.woff") format("woff"),
  //     url("../fonts/saira-extra-condensed-latin-500.woff2") format("woff2");
  // }
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  width: 600px;

  h1 {
    text-decoration: underline;
  }

  .circle {
    align-items: center;
    border-radius: 50%;
    display: flex;
    margin: 0 auto;

    &.outer-circle {
      background: radial-gradient(#fff, #515151);
      height: 420px;
      position: relative;
      width: 420px;
      margin-top: 50px;

      .product-logo {
        border: 1px solid #fff;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-family: 'Saira Extra Condensed', sans-serif;
        height: 120px;
        width: 120px;
        position: absolute;
        top: 37%;

        &.ochre-logo {
          background: #f29e10;
          right: -10px;
        }

        &.green-logo {
          background: #61b26c;
          left: -10px;
        }

        small {
          margin: 5px 0 -5px 0;
        }

        span {
          font-size: 25px;
          margin: 0 auto;
          text-transform: uppercase;
        }

        i {
          font-size: 40px;
        }
      }
    }

    &.inner-circle {
      background: #fff;
      height: 220px;
      width: 220px;
    }

    &.core-circle {
      border: 1px dashed lightgray;
      height: 145px;
      justify-content: center;
      position: relative;
      width: 145px;

      i.fa {
        background-color: white;
        border: 1px solid #03a9f4;
        border-radius: 50%;
        color: #03a9f4;
        cursor: pointer;
        font-size: x-large;
        padding: 5px;
        position: absolute;

        &.fa-cogs {
          bottom: 0;
          left: 5px;
        }
        &.fa-rotate-left {
          bottom: 35px;
          left: -15px;
        }
      }
    }
  }

  div.break-bar {
    background: #fff;
    height: 15px;
    position: absolute;
    width: 101px;

    &.top-bar {
      left: 38%;
      top: 42px;
      -ms-transform: rotate(90deg); /* IE 9 */
      -webkit-transform: rotate(90deg); /* Safari */
      transform: rotate(90deg);
    }

    &.left-bar {
      left: 20px;
      top: 67%;
      -ms-transform: rotate(-30deg); /* IE 9 */
      -webkit-transform: rotate(-30deg); /* Safari */
      transform: rotate(-30deg);
    }

    &.right-bar {
      right: 20px;
      top: 67%;
      -ms-transform: rotate(30deg); /* IE 9 */
      -webkit-transform: rotate(30deg); /* Safari */
      transform: rotate(30deg);
    }
  }

  .service-group {
    border-bottom: 1px solid #333;
    position: absolute;
    width: 310px;

    span {
      font-size: 12px;
    }

    &.service-group--top {
      top: 0;
    }

    &.service-group--right {
      right: -60%;
      text-align: left;

      &:before {
        border-bottom: 1px solid #333;
        content: "";
        left: -30px;
        position: absolute;
        width: 35px;
        z-index: -1;
      }
    }

    &.service-group--bottom {
      top: 90%;
    }

    &.service-group--left {
      left: -60%;
      text-align: right;

      &:after {
        border-bottom: 1px solid #333;
        content: "";
        right: -30px;
        position: absolute;
        width: 35px;
        z-index: -1;
      }
    }

    &.service-group--upper-middle {
      top: 30%;
      width: 280px;
    }

    &.service-group--lower-middle {
      top: 60%;
      width: 280px;
    }

    &.service-group--upper-middle.service-group--right,
    &.service-group--lower-middle.service-group--right {
      right: -70%;
    }

    &.service-group--upper-middle.service-group--left,
    &.service-group--lower-middle.service-group--left {
      left: -70%;
    }

    &.service-group--lower-middle.service-group--right,
    &.service-group--bottom.service-group--right {
      &:before {
        transform: rotate(45deg);
        bottom: 12px;
      }
    }

    &.service-group--top.service-group--right,
    &.service-group--upper-middle.service-group--right {
      &:before {
        transform: rotate(-45deg);
        top: 37px;
      }
    }

    &.service-group--top.service-group--left,
    &.service-group--upper-middle.service-group--left {
      &:after {
        top: 36px;
        transform: rotate(45deg);
      }
    }

    &.service-group--bottom.service-group--left,
    &.service-group--lower-middle.service-group--left {
      &:after {
        bottom: 12px;
        transform: rotate(-45deg);
      }
    }
  }

  .service-group__adaptors {
    channel-list {
      display: flex;
      margin: 10px 40px;
      position: absolute;
    }

    &.service-group__adaptors--left {
      right: 0;
    }
  }

  .service-group__adaptor {
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    margin-right: 10px;
    position: relative;
    width: 60px;

    img {
      background-color: #fff;
      border: 1px solid lightgray;
      border-radius: 5px;
      max-height: 60px;
      padding: 3px;
      width: 60px;
    }

    i.fa {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      padding: 2px;
      border: 1px solid #03a9f4;
      border-radius: 50%;
      color: #03a9f4;
    }
  }

  .service-group__adaptor--empty {
    border: 1px dashed #666;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    margin-right: 10px;
    position: relative;
    width: 60px;

    &:before {
      bottom: -17px;
      color: #666;
      content: "Add";
      left: 20px;
      font-size: 12px;
      position: absolute;
    }

    &:after {
      color: #999;
      content: "+";
      font-size: 60px;
      left: 0.6rem;
      line-height: 1;
      font-weight: 600;
      position: absolute;
      top: -0.5rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .entity-counter {
    position: absolute;
    color: #fff;

    &.entity-counter--top {
      top: 7%;
    }

    &.entity-counter--right {
      right: 25%;
    }

    &.entity-counter--bottom {
      bottom: 10%;
      left: 30%;
    }
    &.entity-counter--left {
      left: 28%;

      .entity-counter__count-tag {
        float: right;
      }
    }

    .entity-counter__title {
      display: block;
      font-family: "Nimbus", Arial, Helvetica, sans-serif;
      font-size: 20px;
    }

    .entity-counter__count-tag {
      float: left;
      font-size: 10px;
      width: 40%;
    }

    .entity-counter__number {
      float: left;
      font-size: 25px;
      line-height: 1;
      width: 60%;
    }
  }

  // arrows
  .arrows {
    position: absolute;

    &.arrows--bottom {
      bottom: 95px;
      left: 47%;
    }

    &.arrows--top {
      top: 117px;
      right: 145px;
      transform: rotate(33deg);
    }

    .arrow {
      border-left: 5px solid #bebebe;
      display: inline-block;
      height: 38px;
      margin: 0px 3px;
      z-index: 1;

      .arrow__arrowhead {
        border-bottom-right-radius: 3px;
        border-right: 3px solid #bebebe;
        border-top: 3px solid #bebebe;
        border-top-left-radius: 3px;
        border-top-right-radius: 2px;
        height: 10px;
        position: absolute;
        width: 10px;

        &.arrow__arrowhead--up {
          left: 1px;
          transform: rotate(-45deg);
        }

        &.arrow__arrowhead--down {
          right: 0;
          transform: rotate(135deg);
          bottom: 5px;
        }
      }
    }
  }

  // status wrapper
  .status-wrapper {
    background-color: #fff;
    height: 50px;
    position: absolute;
    width: 50px;

    &.status-wrapper--top {
      top: 20%;
    }
    &.status-wrapper--right {
      right: 15%;
    }
    &.status-wrapper--left {
      left: 15%;
    }
    &.status-wrapper--bottom.status-wrapper--right {
      bottom: 15%;
      right: 20%;
    }
    &.status-wrapper--bottom.status-wrapper--left {
      bottom: 8%;
      right: -8%;
    }

    .status-wrapper__content {
      border: 1px solid grey;
      height: 45px;
      width: 45px;

      &.status-wrapper__content--success {
        border: 1px solid lightgreen;
        color: lightgreen;
      }
      &.status-wrapper__content--warning {
        border: 1px solid yellow;
        color: yellow;
      }
      &.status-wrapper__content--danger {
        border: 1px solid lightcoral;
        color: lightcoral;
      }
    }
  }

  // checkbox switch
  .switch {
    clear: both;

    &.switch--right {
      position: absolute;
      right: -20px;
      top: -2px;
    }

    &.switch--left {
      position: absolute;
      left: 0px;
      top: -2px;
    }
  }

  input.switch__checkbox {
    position: absolute;
    left: -99999px;
    top: -99999px;

    & + label {
      position: relative;
      float: left;
      line-height: 1em;
      height: 1em;
      padding-left: 3.5em;
      margin: 0.2em 0;
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        left: 0;
        content: "";
        line-height: 1em;
        -webkit-transition: all 0.1s cubic-bezier(0.72, 0.21, 0.95, 0.64);
        transition: all 0.1s cubic-bezier(0.72, 0.21, 0.95, 0.64);
      }

      &::before {
        width: 2em;
        top: 0;
        bottom: 0;
        background-color: transparent;
        border: 1px solid #777;
        border-radius: 1em;
        //box-shadow: inset 0.13em 0.13em 0.2em rgba(0,0,0,0.3);
      }

      &::after {
        width: 0.75em;
        top: 0.125em;
        bottom: 0.125em;
        margin-left: 0.1em;
        border-radius: 50%;
        background-color: #777;
      }
    }

    &:checked + label {
      &::before {
        background: #7fd322;
      }
      &::after {
        background-color: #fff;
        margin-left: 1.25em;
      }
    }
  }

  // utilities
  .ml-2 {
    margin-left: 0.625rem;
  }
  .ml-9 {
    margin-left: 2.813rem;
  }
  .mr-2 {
    margin-right: 0.625rem;
  }
  .mr-9 {
    margin-right: 2.813rem;
  }

  .ta-l {
    text-align: left;
  }
  .ta-r {
    text-align: right;
  }
}

.c-default {
  cursor: default !important;
}
