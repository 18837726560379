$sticky-footer-height: 30px;
$sticky-header-height: 46px;
$header-background-color: rgb(85,85,85);
$header-background-color-2: rgb(77, 165, 197);
$footer-background-color: rgb(85,85,85);
$page-background-color: rgb(249,249,249);
$component-background-color: rgb(255,255,255);
$component-border: 1px solid rgb(221,221,221);

// MAIN COLORS
//--------------------------------------------------------
$white: rgb(255,255,255);
$black: rgb(0,0,0);
$theme-color: rgb(85,85,85);
$theme-color-border-bottom: rgb(216,216,216);

$theme-second-color: rgb(77, 165, 197); //
$theme-third-color: rgb(66,141,169); //darkest
$theme-light-color: rgba(150, 181, 192, 0.999); //lightest
$theme-color-text: rgb(78,165,197); //middle
$theme-light-color-text: rgba(255, 255, 255, 0.999);
$theme-dark-color: rgba(0, 0, 0, 0.999);
$theme-small-icon-color: rgb(115,191,31);
$theme-light-dark: rgb(61,73,84);
//--------------------------------------------------------

// Warehouse Colors
//--------------------------------------------------------
$theme-color-wh-green: rgb(12,165,91);
$theme-color-wh-orange: rgb(255,171,67);
$theme-color-wh-darkblue: rgb(74,74,74);
$theme-color-wh-lightblue: rgb(19,122,206);
$theme-color-wh-red: rgb(206, 35, 19);
$theme-color-wh-purple: rgb(131, 19, 206);
$theme-color-wh-crail: rgb(197,93,68);
$theme-color-wh-saltbox: rgb(109,98,112);
$theme-color-wh-sandrift: rgb(178,147,130);
$theme-color-wh-calypso: rgb(60,128,152);
//--------------------------------------------------------



$active-row-background-color: rgb(225,245,251);

//--------------------------------------------------------

$border-darken-amount: 3;
$thick-border-width: 5px;
$thin-border-width: 1px;
$border-radius: 5px;
$border-width: 1px;
// $thick-border-width: 0.3125rem;

//AdminLTE 2 Variables.less
//=========================

//PATHS
//--------------------------------------------------------

//$boxed-layout-bg-image-path: "../img/boxed-bg.png";

//COLORS
//--------------------------------------------------------
//Primary
$light-blue: rgb(60,141,188);
//Danger
$red: rgb(221, 75, 57);
$soft-red: rgba(250, 120, 103, 0.842);
//Success
$green: rgb(0, 166, 90);
//Info
$aqua: rgb(0, 192, 239);
//Warning
$yellow: rgb(243, 156, 18);
$blue: rgb(26, 28, 34);
$navy: rgb(0, 31, 63);
$teal: rgb(78, 165, 197);
$olive: rgb(61, 153, 112);
$lime: rgb(1, 255, 112);
$orange: rgb(255, 133, 27);
$fuchsia: rgb(240, 18, 190);
$purple: rgb(96, 92, 168);
$maroon: rgb(216, 27, 96);
$black: rgb(17, 17, 17);
$gray: rgb(210, 214, 222);
//No results
$gray-dif: rgb(153, 153, 153);


$greyed-background-color: rgb(238, 238, 238);
$greyed-border-color: rgb(221, 221, 221);

$gray-standard:rgb(238, 238, 238);
$gray-darken:darken($gray-standard,8%);
$gray-lighten:lighten($gray-standard,8%);

//Z-INDEX
//--------------------------------------------------------
$header-footer-z-index: 550;
$scrollbox-sticky-z-index:400;


//BOXES
//--------------------------------------------------------
$box-border-color: rgb(244, 244, 244);
$box-border-radius: 3px;
$box-footer-bg: rgb(246, 246, 246);
$box-boxshadow: 0 1px 1px rgba(0, 0, 0, .1);
$box-padding: 10px;

//Box variants
$box-default-border-top-color: rgb(210, 214, 222);

//DIRECT CHAT
//--------------------------------------------------------
$direct-chat-height: 250px;
$direct-chat-default-msg-bg: $gray;
$direct-chat-default-font-color: rgb(68, 68, 68);
$direct-chat-default-msg-border-color: $gray;

//CHAT WIDGET
//--------------------------------------------------------
$attachment-border-radius: 3px;

// LEGEND
//--------------------------------------------------------
$legend-height: 32px;
$legend-font-size: 1.4rem;


// SCROLLBOX
//--------------------------------------------------------
$scrollbox-header-height: 32px;
$scrollbox-footer-height: 30px;
$scrollbox-footer-width: 89%;

// SIDE-MENU
//--------------------------------------------------------
//widths
// $side-menu-icon-width: 2rem;
// $side-menu-toggler-width: 0.8rem;
// $side-menu-text-width: 8rem;
// $side-menu-branch-width:.5rem;
// $side-menu-branch-border-width:2px;

$side-menu-icon-width:30px;
$side-menu-toggler-width:9px;
$side-menu-text-width:100px;
$side-menu-branch-width:6px;
$side-menu-branch-border-width:2px;

$side-menu-padding-left:6px;
$side-menu-padding-right:18px;

$side-menu-closed-total-width: $side-menu-icon-width + $side-menu-padding-left + $side-menu-padding-right + $thick-border-width;// 1px border
$side-menu-open-total-width: $side-menu-closed-total-width + $side-menu-text-width + 3px;

//heights
$side-menu-item-height:30px;
$side-menu-branch-height:15px;

// APP-SHELL
//--------------------------------------------------------
$app-shell-main-content-padding:8px;

// Synkd logo
//--------------------------------------------------------
$logo-foreground-color: $theme-color;
$logo-d-foreground-color: $theme-light-color-text;
$logo-d-background-color: $theme-second-color;
$logo-dark-color: $theme-dark-color;

$app-image-directory: "../../images/svg/";
$logo-main: ( 
    sheet-url: url($app-image-directory + "largeRocket.svg"),
    background-pos: 0px 0px,
    width: 200px,
    height: 60px,
    sheet-url-x2: url($app-image-directory + "largeRocket.svg"),
    image-width-x2: 200px,
    image-height-x2: 60px,
    background-pos-x2: 0px 0px
);

// Spenda logo
//--------------------------------------------------------
$spenda-logo-main: (
    sheet-url: url($app-image-directory + "SpendaWhale.svg"),
    background-pos: 0px 50px,
    width: 450px,
    height: 470px,
    sheet-url-x2: url($app-image-directory + "SpendaWhale.svg"),
    image-width-x2: 450px,
    image-height-x2: 470px,
    background-pos-x2: 0px 50px
);

// PARENT-CHILD
//--------------------------------------------------------
$business-code-search-height: 36% ;
$business-code-search-mt: 7%;

//Item Selector
//--------------------------------------------------------
$suggestion-list-font-size: 13px;
$suggestion-list-height-default: 14rem;
$suggestion-list-height-big: 20rem;

// SYNK-STATUS-PANEL
//--------------------------------------------------------
$panel-separator: gainsboro;
$no-icon: lightgray;

// Channel Configuration settings
//--------------------------------------------------------
$default-icon-size: 1.2em;

//Match and Link Account Customer Wizard
//--------------------------------------------------------
$btn-back-position-left: -205px;
$floating-btn-position-left: -40px;
// headers 
$sub-header-margin-bottom: 1em;
$sub-header-padding-bottom: 5px;
// paddings
$padding-x: 80px;
$padding-y: 20px;

//--------------------------------------------------------

// General Grouping
//--------------------------------------------------------
$gg-background: #4DA5C5;
$gg-background-add: #428DA9;  
$gg-backround-border: #245364; 
$gg-edit-border: 3px solid #0CA55B;
$gg-save: #4EA5C5 
