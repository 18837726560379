ux-dialog-overlay.active {
    background-color: black;
    opacity: 0.5 !important;
}

// https://github.com/aurelia/dialog/issues/183
ux-dialog-container {
    display:flex !important;
    align-items: center;
    justify-content: center;
}
ux-dialog-container > div {
    margin:auto !important;
}

.modal-body {
    border-bottom: 1px solid #e9ecef;
    margin-bottom: -1px;
}

.modal-header {
    background-color: $header-background-color-2;
    color: $white;
    button.close {
        color: $white;
    }
}
