.icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 80px;
    width:80px;
    
    &.center-with-text {
        vertical-align: bottom;
    }

    &.icon--xs {
        height: 0.8rem;
        width: 0.8rem;
    }

    &.icon--sm {
        height: 1.5rem;
        width:1.5rem;
    }
    
    &.icon--md {
        height: 2rem;
        width: 2rem;
    }

    &.icon--lg {
        height: 3rem;
        width:3rem;
    }
}

legend .icon {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
}

.icon-btn {
    @extend .icon;

    margin: 20%;
    width: 60%;
    height: 60%;
}

.parent-ring-btn {
    cursor: pointer;
    padding: 0.3em;

    &:hover, &:focus {
        >.ring-btn {
            >.icon-btn {
                -webkit-transition: all 0.2s ease-in-out;
                -moz-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
            }
        }
    }

    .ring-btn-text {
        vertical-align: middle;
        display: inline-block;
    }

    >.ring-btn {
        vertical-align: middle;
    }
}

.ring-btn {
    @extend .theme-second-background-color;
    @extend .theme-second-border-color;

    border-width: 2px;
    border-style: solid;
    border-radius: 30px;

    cursor: pointer;
    margin: 5px;
    display: inline-block;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    text-align: center;
}

@mixin ring-btn-hover-shadow($backgroundColour) {
    box-shadow: inset 0px 0px 0px 0px $backgroundColour;
}

button.parent-ring-btn {
    background-image: none;
    background-color: transparent;
    border-color: transparent;
    &:focus {
        outline:none;
    }

    >.ring-btn {
        >.fa {
            vertical-align: middle;
        }
    }
}

a.parent-ring-btn {
    color: black;
    display: inline-block;

    &:hover, &:focus {
        text-decoration: none;
    }
}

.parent-ring-btn {
    &:hover, &:focus, &.active-override {
        >.ring-btn {
            @include ring-btn-hover-shadow($page-background-color);

            >.fa {
                color: white;
            }
        }

        .dark {
            display: none;
        }

        .light {
            display: inherit;
        }
    }
}

.ring-btn {
    width: 1.8em;
    height: 1.8em;

    -webkit-box-shadow: inset 0px 0px 0px 1em $page-background-color;
    -moz-box-shadow: inset 0px 0px 0px 1em $page-background-color;
    box-shadow: inset 0px 0px 0px 1em $page-background-color;

    .dark {
        display: inherit;
    }

    .light {
        display: none;
    }
}

#sticky-header-container {
    .ring-btn {
        width: 2.5em;
        height: 2.5em;

        -webkit-box-shadow: inset 0px 0px 0px 20px $header-background-color;
        -moz-box-shadow: inset 0px 0px 0px 20px $header-background-color;
        box-shadow: inset 0px 0px 0px 20px $header-background-color;
    }

    .parent-ring-btn {
        &:hover {
            >.ring-btn {
                @include ring-btn-hover-shadow($header-background-color);

                >.icon-btn {
                    margin: 15%;
                    width: 70%;
                    height: 70%;
                }
            }
        }
    }
}
