/*
 * Component: Direct Chat
 * ----------------------
 */
.direct-chat-container{
    padding: 0;
    position: fixed;
    left: 100%;
    top: 35%;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    z-index: 9;

    &.open {
      transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
    }
}

.direct-chat-toggle{
    background: #fff;
    border-top: 3px solid #3c8dbc;
    border-top-left-radius: 3px;
    box-shadow: -4px 4px 5px rgba(0,0,0,0.15);
    cursor: pointer;
    width: 50px;
    position: absolute;
    left: -50px;
    top: -3px;
    height: 50px;
    text-align: center;
}

.direct-chat {
    border-top-left-radius: 0 !important;

  .box-body {
    @include border-bottom-radius(0);
    position: relative;
    overflow-x: hidden;
    padding: 0;
  }
  &.chat-pane-open {
    .direct-chat-attachments {
      @include translate(0, 0);
    }
  }
}

.direct-chat-messages {
  @include translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.direct-chat-msg,
.direct-chat-text {
  display: block;
}

.direct-chat-msg {
  @include clearfix();
  margin-bottom: 10px;
}

.direct-chat-messages,
.direct-chat-attachments {
  @include transition-transform(.5s ease-in-out);
}

.direct-chat-text {

  @include border-radius(5px,5px,5px,5px);
  position: relative;
  padding: 5px 10px;
  background: $direct-chat-default-msg-bg;
  border: 1px solid $direct-chat-default-msg-border-color;
  margin: 5px 50px 0 0;
  color: $direct-chat-default-font-color;


  //Create the arrow
  &:after,
  &:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: $direct-chat-default-msg-border-color;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &:after {
    border-width: 5px;
    margin-top: -5px;
  }
  &:before {
    border-width: 6px;
    margin-top: -6px;
  }
  .right & {
    margin-right: 0;
    margin-left: 50px;
    &:after,
    &:before {
      right: auto;
      left: 100%;
      border-right-color: transparent;
      border-left-color: $direct-chat-default-msg-border-color;
    }
  }
}

.direct-chat-img {
  @include border-radius(50%,50%,50%,50%);
  float: left;
  width: 40px;
  height: 40px;
  .right & {
    float: right;
  }
}

.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}

.direct-chat-name {
  font-weight: 600;
}

.direct-chat-timestamp {
  color: #999;
}

//Direct chat attachments pane
.direct-chat-attachments-open {
  .direct-chat-attachments {
    @include translate(0, 0);
  }
}

.direct-chat-attachments {
  @include translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #fff;
  overflow: auto;
}

//attachments list -- for displaying attachments in direct chat attachments pane
.attachments-list {
  @extend .list-unstyled;
  > li {
    @include clearfix();
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 10px;
    text-align: center;
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.attachments-list-img {
  @include border-radius(50%,50%,50%,50%);
  width: 40px;
  float: left;
}

.attachments-list-info {
  //margin-left: 45px;
  color: #fff;
}

.attachments-list-name,
.attachments-list-status {
  display: block;
}

.attachments-list-name {
  font-weight: 600;
}

.attachments-list-status {
  font-size: 12px;
}

.attachments-list-date {
  color: #aaa;
  font-weight: normal;
  word-wrap: break-word;
}

.attachments-list-msg {
  color: #999;
}

//Direct Chat Variants
.direct-chat-danger {
  @include direct-chat-variant($red);
}

.direct-chat-primary {
  @include direct-chat-variant($light-blue);
}

.direct-chat-warning {
  @include direct-chat-variant($yellow);
}

.direct-chat-info {
  @include direct-chat-variant($aqua);
}

.direct-chat-success {
  @include direct-chat-variant($green);
}