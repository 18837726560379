@import "./claims/claims";
@import "./customers/customer-details";
@import "./customers/customers";
@import "./invoices/invoice-details";
@import "./invoices/invoice-modal";
@import "./notifications/notifications";
@import "./public/public";
@import "./sales-orders/sales-order-details";
@import "./synkd/adaptors/adaptors";
@import "./synkd/connect/connect";
@import "./synkd/data-manager/data-manager";
@import "./sales/sales";
@import "./settings/settings";
@import "./support/dashboard";
@import "./reports/report-list";
@import "./synkd/transaction-queue/transaction-queue";
@import "./inventory-management/inventory-management";

