
customer-details {
    background-color: lightyellow;
   
    .theme-op-frame {
        @extend .theme-frame;
        padding: 2rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        max-width: 370px;
    }

}


.color--gray {
    color: var(--gray);
}

.size--small {
   font-size: $suggestion-list-font-size;
}

