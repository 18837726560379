.adaptor-list-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px;

  h2 {
    margin-bottom: 1rem;
  }

  input[name="search-term"] {
    background-color: #e4e4e4;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    text-align: center;
    width: 40%;
  }
}

.adaptor-list {
  margin-top: 1rem;
  max-width: 600px;
  overflow-x: auto;
}

.adaptor-list--empty {
  height: 215px;
  text-align: center;
}

.adaptor-item {
  display: inline-block;
  margin: 0 15px 15px 15px;
  text-align: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .adaptor-item__logo {
    align-items: center;
    border: 1px solid rgba(189, 188, 188, 1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 130px;
    justify-content: center;
    padding: 15px;
    width: 130px;

    img {
      max-height: 100px;
      max-width: 100px;
    }
  }

  .adaptor-item__name {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
  }
}
