// This file contains reusable subatomic utility classes.
// Useful for reusable css rules that no necessarily
// should be added at component level.
// This classes should be prefered rather than adding individual
// rulesets to the component itself.
// Check if a utility class does not already exist before adding a new one.
// https://getbootstrap.com/docs/4.0/utilities
// Fonts
.fs-100 {
    font-size: 100%
}

.fs-90 {
    font-size: 90%
}

.fs-80 {
    font-size: 80%
}

.font-weight-bold {
    font-weight: 600 !important;
}

.text-xl {
    font-size: 1.25rem;
}

// Cursor
.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}

// Text
.text-low-contrast {
    color: #868e96;
}

hr.separator {
    height: 1px;
    margin: 2.5rem auto;
    width: 100px;
}

// Opacity
.opacity-25 {
    opacity: 0.25;
}

.opacity-50 {
    opacity: 0.5;
}

// Colors 
.color--primary {
    color: $theme-color-text;
}

.color--danger {
    color: #DC3545 !important;
}

.color--warning {
    color: #D4A04A !important;
}

.color--white {
    color: white;
}

.bg-light {
    background: #DEDEDE !important;
}

.flex {
    display: flex;
}

// Flex
.flex-basis-5 {
    flex-basis: 5%;
}

.flex-basis-6 {
    flex-basis: 6%;
}

.flex-basis-7 {
    flex-basis: 7%;
}

.flex-basis-10 {
    flex-basis: 10%;
}

.flex-basis-25 {
    flex-basis: 25%;
}

.flex-basis-30 {
    flex-basis: 30%;
}

// This should be removed once bootstrap version is bumped up to at least 4.2.1 
.flex-grow-1 {
    flex-grow: 1;
}

// Margin: Additional margin utilities not supported by bootstrap
.mb-6 {
    margin-bottom: 5rem;
}

.mr-6 {
    margin-right: 5rem;
}

.w-auto {
    width: auto !important;
}

.w-17 {
    width: 17% !important;
}

// border 
.border-dashed {
    border: 1px dashed gray;
}

// Z Index
.zindex-dropdown {
    z-index: $zindex-dropdown;
}