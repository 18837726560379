@import "./edit-stocktake";
@import "./commit-stocktake-details";


.pad-5 {
    padding-right: 5.25rem;
}

.pad-4 {
    padding-right: 4rem;
}

warehouses-list-item {
    display: block;
    position: relative;
}

modal#commit-stocktake-modal {
    display: block;
    min-width: 60rem;
    max-height: 40rem;
    overflow: auto;
}


.warehouses-list {
    display: grid;
    grid-template-columns: 70px 290px 160px 1fr 1fr 1fr 1fr 1fr minmax(30px, 60px);
    margin-bottom: 2rem;

    > span.group-header {
        background: #4EA5C5;
        color: #FFF;
        // border: 1px solid #FFF;
    }
}

.group-children-toggle {
    transform: scaleX(1.125);
    left: -3px;
    position: relative;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.group-header-add-new {
    background: #4389a2 !important;
    // border-top-right-radius: 5px;
    border: 1px solid gray;
}

warehouses-list-item {
    grid-column: 1/10;
    display: grid;
    grid-template-columns: 70px 290px 160px 1fr 1fr 1fr 1fr 1fr minmax(30px, 60px);
    border-bottom: 1px solid lightgray;

    > div {
        border-right: 1px solid lightgray;

        &:first-child {
            border-left: 1px solid lightgray;
        }
    }
}

.group-child-actions {
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

.rotate-270{
    transform: rotate(270deg);
}

.rotate-90{
    transform: rotate(90deg);
}

.warehouse-type-selector {
    width: 8rem;
    background: lightgray;
    top: 2rem;
    left: 0;
    
    span {
        &:hover {
            background: gray;
        }
    }
}