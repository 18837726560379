
.checkboxes label, .tickboxes label {
    font-weight: normal;
    display:block;
}

.shipping, .accounts{
    p{
        font-weight: normal;
    }
}

inventory-details {
    h5 {
        margin-right: 3rem;
    }

    .active-status {
        position: absolute;
        right: 3rem;
        top: 2.5rem;
        .active-btn{
            background-color: #d4a04a !important;
            border-radius: .25rem;
            color: #FFF;
            padding: .45rem;
        }
        .archived-btn{
            background-color: #4EA5C5 !important;
            border-radius: .25rem;
            color: #FFF;
            padding: .45rem;
        }
    }
}

.badge-variant-pill {
    font-size: 12px !important;
    font-weight: 500 !important;
    position: relative;
    top: -3px;
}

.list-variant {
    // width: 50%;
    margin-top: 5px;
}


