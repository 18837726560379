
.invoice-details-column {
    @include make-col-ready();

    @include media-breakpoint-up(xs) {
        @include make-col(12);
    }
    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    @include media-breakpoint-up(md) {
        @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
        @include make-col(4);
    }
    @include media-breakpoint-up(xl) {
        @include make-col(4);
    }
}

.invoice-details {
    border-bottom: 1px solid #EEE;
    margin-bottom: 20px;
    padding-bottom: 9px;
}

.break-comments{
    word-wrap: break-word;
}

.simple-payment-list{
    
    td {
        border: 1px solid transparent;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        padding: .375rem .75rem;
        text-align: center;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
    }
    th {
        @extend td;
        background: $theme-color-text;
        color: $component-background-color;
    } 
}

.simple-linked-transaction-list{
    @extend .simple-payment-list;
}
