@import "./synkd.variables.colours";
@import "./synkd.variables.images";

.Synkd {
    @import "./../base/base";

    .logo-header {
        @extend .icon-btn;
        @extend .synkd-light;
    }
}