
.dividing-rule{
    .horizontal,
    .vertical {
        background-color: $greyed-border-color;
    }

    .horizontal {
        width: 100%;
        height: $border-width;
        margin-top:$border-width * -0.5;
        margin-bottom:$border-width * -0.5;
    }

    .vertical {
        width: $border-width;
        height: 100%;
        margin-left:$border-width * -0.5;
        margin-right:$border-width * -0.5;
    }
}