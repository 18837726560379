
.loading {
    opacity:    0.5;
    background: #000;
    width:      100%;
    height:     100%;
    z-index:    10000;
    top:        0;
    left:       0;
    position:   fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-icon {
    color:      white;
}