.notification-count {
    @extend .badge;
    @extend .badge-pill;
    @extend .badge-danger;

    position: absolute;
    z-index: 1;
    
    &.notification-count--top-right {
      top: 0px; 
      right: -3px;
    }

    &.notification-count--left {
      top: 4px;
      left: 12px;
    }
}



/*
 * Component: Dropdown menus
 * -------------------------
 */

.messages-menu {
  //fix width and padding
  > .dropdown-menu {
    > li {
      position: relative;
    }
    width: 500px;
    //Remove padding and margins
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
  }
  //Define header class
  > .dropdown-menu > li.header {
    @include border-radius(4px, 4px, 0, 0);
    background-color: #ffffff;
    padding: 7px 10px;
    border-bottom: 1px solid #f4f4f4;
    color: #444444;
    font-size: 14px;
  }

  //Define footer class
  > .dropdown-menu > li.footer > a {
    @include border-radius(0, 0, 4px, 4px);
    font-size: 12px;
    // background-color: #fff;
    padding: 7px 10px;
    border-bottom: 1px solid #eeeeee;
    color: #FFF !important;
    // @media (max-width: $screen-sm-max) {
    //   background: #fff !important;
    //   color: #444 !important;
    // }
    text-align: center;
    //Hover state
    &:hover {
      text-decoration: underline;
      font-weight: normal;
    }
  }

  //Clear inner menu padding and margins
  > .dropdown-menu > li .menu {
    //max-height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;
    > li > a {
      display: block;
      //white-space: nowrap; /* Prevent text from breaking */
      border-bottom: 1px solid #f4f4f4;
      // Hove state
      &:hover {
        background: #f4f4f4;
        text-decoration: none;
        
      }
    }
  }
}

//Messages menu
.messages-menu {
  //Inner menu
  > .dropdown-menu > li .menu {
    // Messages menu item
    > li > a {
      margin: 0;
      //line-height: 20px;
      padding: 10px 10px;
      // User image
      > div > img {
        margin: auto 10px auto auto;
        width: 40px;
        height: 40px;
      }
      // Message heading
      > h4 {
        padding: 0;
        margin: 0 0 0 45px;
        color: #444444;
        font-size: 15px;
        position: relative;
        // Small for message time display
        > small {
          color: #999999;
          font-size: 10px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      > p {
        margin: 0 0 0 45px;
        font-size: 12px;
        color: #888888;
        word-break: break-all;
        width: 400px;
        /* border: 1px solid #000000; */
        margin: 0 auto;
        width: 400px;
        word-break: keep-all;
      }

      @include clearfix();

    }

  }
}