.new-status, .not_sent-status, .open-status {
    @extend .new-status-dark;
}

.sent-status {
    @extend .sent-status-dark;
}

.processing-status, .payment_pending-status, .requires_approval-status, .review-status, .on_hold-status {
    @extend .processing-status-dark;
}

.sent_as_pdf-status, .sent_as_unlinked-status {
    
    @extend .processing-status-dark;
}

.sent_as_pdf-status-true {
    @extend .archived-true;
}

.sent_as_pdf-status-false {
    @extend .archived-false;
}

.sending-status {
    @extend .sending-status-dark;
}

.approved-status, .accepted-status, .closed-status {
    @extend .approved-status-dark;
}

.rejected-status, .cancelled-status, .not_approved-status, .closed_rejected-status {
    @extend .rejected-status-dark;
}

.in_picking-status, .partially_picked-status, .picked-status, .in_transit-status {
    @extend .picking-status-dark;
}

// These get defined after so active overrides the non-active ones
.active:not(.active-override) {
    .new-status, .not_sent-status, .open-status {
        @extend .new-status-light;
    }
    
    .sent-status {
        @extend .sent-status-light;
    }

    .processing-status, .payment_pending-status, .requires_approval-status, .review-status, .on_hold-status {
        @extend .processing-status-light;
    }

    .sent_as_pdf-status, .sent_as_unlinked-status {
        @extend .processing-status-light;
    }
    
    .sending-status {
        @extend .sending-status-light;
    }

    .approved-status, .accepted-status, .closed-status {
        @extend .approved-status-light;
    }

    .rejected-status, .cancelled-status, .not_approved-status, .closed_rejected-status {
        @extend .rejected-status-light;
    }

    .in_picking-status, .partially_picked-status, .picked-status, .in_transit-status {
        @extend .picking-status-light;
    }
}


.not_sent-status-light, .open-status-light {
    @extend .new-status-light;
}
.payment_pending-status-light, .requires_approval-status-light, .review-status-light, .on_hold-status-light {
    @extend .processing-status-light;
}
.sent_as_pdf-status-light, .sent_as_unlinked-status-light {
    @extend .processing-status-light;
}
.accepted-status-light, .closed-status-light {
    @extend .approved-status-light;
}
.cancelled-status-light, .not_approved-status-light, .closed_rejected-status-light {
    @extend .rejected-status-light;
}
.in_picking-status-light, .partially_picked-status-light, .picked-status-light, .in_transit-status-light {
    @extend .picking-status-light;
}