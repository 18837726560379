
.btn-std{
    border-color: $greyed-border-color;
    border-style: solid;
    border-width: $border-width;
    background-color: $theme-second-color;
    outline: 0;
    color: $theme-light-color-text;
    box-shadow: none !important;
    &:hover:not(:disabled)
    &:active:not(:disabled),
    &.active-override:not(:disabled){
        background-color: $theme-light-color-text;
        transition: all 0.3s;
        border-radius: 5px;
        color: $theme-color-text;
    }
}

.btn-highlight{

    color: $theme-second-color;
    background-color: $page-background-color;
    border-radius: 5px;

    &:hover:not(:disabled):not(.active-override){
        background-color: $theme-second-color;
        transition: all 0.3s;
        // border-radius: 5px;
        color: $theme-light-color-text;
    }
    &.active-override:not(:disabled),
    &:active:not(:disabled)
    {
        @extend .theme-border-color;
        // border-bottom-width: 1.4rem;
        // border-radius: 5px;
        .labels{
            .friendly-name{
                bottom: 5px;
                color: $theme-light-color-text;
                align-content: center;
                position: absolute;
            }
        }
    }

    &:disabled{
        @extend .form-control:disabled;
    }
    i{
        position: relative;
        bottom:1px;
    }
}

.btn-tiny-highlight{

    color: $theme-second-color;
    background-color: $page-background-color;
    border-radius: 5px;

    &:hover:not(:disabled):not(.active-override){
        background-color: $theme-second-color;
        transition: all 0.3s;
        //border-radius: 5px;
        color: $theme-light-color-text;
    }
    &.active-override:not(:disabled),
    &:active:not(:disabled)
    {
        @extend .theme-border-color;
        // border-bottom-width: 1.4rem;
        background-color: $theme-second-color;
        transition: all 0.3s;
        // border-radius: 8px;
        color: $theme-light-color-text;
    }
    &:disabled{
        @extend .form-control:disabled;
    }
    i{
        position: relative;
        bottom:1px;
    }
}

button.btn {
    cursor: pointer;
    border-width: 1px;
    border-style: solid;

    &.btn-link {
        border: none;
    }
}

button.btn.disabled {
    cursor: none;
    border: none;
    outline: none !important;
    &:focus{
        outline: none !important;
        border: none;
        box-shadow: none;
    }
    &:hover{
        cursor: not-allowed;
        outline: none !important;
        border: none !important;
        box-shadow: none;
    }
}

button.btn-primary {
     background: $theme-second-color;
     border-color: $input-border-color;
     &:hover:not(:disabled){
        background-color: darken($theme-second-color, 10%);
        color: white;
    }
}

.btn-outline-primary:enabled {
    background: $white;
    color: $theme-second-color;
    border-color: $theme-second-color;
    
    &.btn-no-hover:hover {
        background: $white !important;
    }
    
    &:hover, &:active, &:focus {
        color: $theme-second-color;
        background-color: lighten($theme-second-color, 30%) !important;
    }
}

.btn-outline-primary:disabled {
    background: $white;
    color: $gray-dif;
    border-color: $gray-dif; 

    &:hover:not(:disabled) {
        color: $theme-second-color;
        background-color: lighten($theme-second-color, 30%) !important;
    }
}

.btn-not-allowed {
    &:hover {
        cursor: not-allowed !important;
        text-decoration: none !important;
    }
}

.btn-outline-crail {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-crail;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

.btn-outline-green {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-green;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

.btn-outline-orange {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-orange;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

.btn-outline-darkblue {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-darkblue;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

.btn-outline-lightblue {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-lightblue;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

.btn-outline-theme {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-second-color;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}


.btn-outline-purple {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-purple;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

.btn-outline-red {
    background: $white;
    color:$black ; //$theme-second-color
    border-color: $theme-color-wh-red;

    &:hover, &:active, &:focus .span {
        // color: $white;
        // background-color: lighten($theme-small-icon-color, 30%) !important;
        text-decoration: underline;
    }

}

#register-button {
    margin-top: 24px;
}

.btn.btn-link {
    color: $theme-second-color;
}

.business-search{
    height: $business-code-search-height;
    margin-top: $business-code-search-mt;
    border: none;
}

.btn-back{
    left:$btn-back-position-left;
}

.input-group-btn .btn-transparent{
    background-color: $input-bg;
    border: $input-btn-border-width solid $input-border-color;
    color: $body-color;
    &:hover:not(:disabled){
        // background-color: $input-bg;
        // border: $input-btn-border-width solid $input-border-color;
       // color: white;
    }
    &:focus:not(:disabled){
        // background-color: $input-bg;
        color: $body-color;
    }
    &:disabled{
        @extend .form-control:disabled;
    }
    i{
        position: relative;
        bottom:1px;
    }
}

.btn--sm {
    font-size: 0.75rem;
    padding: 0.125rem 1.5rem;
}
