

$icon-height: 30px;

create-item-button{
    text-align: center;
    .icon-position-class{
        position: absolute;
        top: -4rem;
    }

    .create-item-button-btn {
        @extend .btn;
        @extend .theme-icon-background-color;
        color:  $theme-second-color;
        border: 1px solid $theme-second-color;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:disabled):hover{
            @extend .theme-light-background-color;
            color:$theme-light-color-text;
            background-color: rgb(117, 186, 221) !important;
        }

        &:disabled:hover {
            cursor: default;
        }

        .create-item-button-icon{
            height: $icon-height;
            width: $icon-height;
            margin:auto;
            background-repeat: no-repeat;
            background-position: center;

        }
        .create-item-button-label{
            white-space: normal;
            @extend .font-size-small;
            font-weight: 480;
        }

    }
}
