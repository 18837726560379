.pagination-container {
    .row {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
    }
}

.paging-footer {
    bottom:$scrollbox-footer-height;
    overflow: visible;
    position: fixed;
    z-index:$scrollbox-sticky-z-index;
    width: calc(100% - 160px);
    margin-left: -10px;
}