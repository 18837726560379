

.check-uncheck-empty{
    .custom-control-indicator{
        background-image: none !important; 
        // ideally use a background image for ticked and crossed as it is easier to center.
        text-align:center;
        vertical-align: middle;
        > i {
            position: absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            @extend .font-size-small;
        }
    }

}