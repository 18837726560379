#app-header {
    padding: 1rem 0;

    .logo-main {
        margin-top: 5rem;
        margin-bottom: -5.5rem;
        background-size: auto auto;
    }
    .synk-sub {
        font-family: 'Saira Extra Condensed';
        font-size: 100px;
        color: #555;
    }
}
.checkbox-text-styling {
    user-select: none;
    text-align: center;
    display: inline;
}
.public-shell-main-content {
    margin-bottom: 1rem;
}

.h-100-with-footer {
    height: calc(100vh - #{$sticky-footer-height});
}

.h-100-with-footer-and-header {
    min-height: calc(100vh - #{$sticky-footer-height} - #{$sticky-header-height});
}

.h-max-100-with-footer-and-header {
    max-height: calc(100vh - #{$sticky-footer-height} - #{$sticky-header-height});
}

#login-button{
    width: 144px;
}


#login-id{
    .form-control {
        min-width: 190px;
        text-align: center;
        display: block;
        width: 100%;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-image: none;
        background-clip: padding-box;
        border: none;
        border-bottom: 1px solid lighten($theme-color,50%);
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    }
    @-webkit-keyframes autofill {
        to {
            color: #666;
            background: transparent;
        }
    }

    input:-webkit-autofill {
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
    }
}

.form-control-register {
    min-width: 150px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid lighten($theme-color,50%);
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
}
.margin-register{
    margin-bottom: 50px;

}
