.transaction, .content {
  position: relative;
  background: #fff;
  border: 1px solid #f4f4f4;
  -webkit-box-shadow: 1px 1px 5px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 1px 5px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
  padding: 20px;
  margin: 0 auto;
  max-width: 1800px;
}

.transaction-title {
  margin-top: 0;
}
